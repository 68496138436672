import { Fragment, FC } from "react";
import { Button, Modal } from "antd";
import React from "react";
import Title from "antd/lib/typography/Title";
import IconClose from "resources/images/IconClose";
import classNames from "classnames";

const ModalComponent: FC<{
  title?: any;
  onClose?: any;
  showCloseIcon?: boolean;
  visible: boolean;
  width?: number | string;
  maskClosable?: boolean;
  wrapClassName?: string;
  getContainer?: any;
  destroyOnClose?: boolean;
}> = ({
  children,
  title,
  onClose,
  showCloseIcon = true,
  width,
  destroyOnClose = true,
  maskClosable = true,
  wrapClassName,
  ...props
}) => {
  return (
    <Modal
      footer={null}
      wrapClassName={classNames("modal", wrapClassName)}
      closable={false}
      width={width ?? 565}
      destroyOnClose={destroyOnClose}
      onCancel={onClose}
      maskClosable={maskClosable || !showCloseIcon}
      {...props}
    >
      <Fragment>
        {showCloseIcon && <Button onClick={onClose} className="ant-modal-close" icon={<IconClose />} />}
        <div className="modal__wrap">
          <Title level={5} className="title">
            {title}
          </Title>
          {children}
        </div>
      </Fragment>
    </Modal>
  );
};

export default ModalComponent;
