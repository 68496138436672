import * as React from "react";

function IconArrowDown(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path d="m13 6-5 5-5-5" stroke="#222B45" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconArrowDown;
