import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import PageHeaderComponent from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'utils/customHooks';
import IconSearch from 'resources/images/IconSearch';
import TableComponent from 'components/Table';
import assetColumns from './column';
import { getListAssetStart } from 'redux/asset/slice';
import { selectListAsset } from 'redux/asset/selected';
import { isEmpty } from 'lodash';
import { textIncludes } from 'utils';

const AssetPage = () => {
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectListAsset);

  const [keySearch, setKeySearch] = useState('');

  const handleNameSearch = (data) => {
    setKeySearch(data.target.value);
  };

  const handleSearch = (data) => {
    if (data.key === 'Enter') {
      setKeySearch(keySearch?.trim());
    }
  };

  const handleChangeBlur = () => {
    setKeySearch(keySearch?.trim());
  };

  const listAsset = (assets) =>
    assets
      ?.filter((item) => {
        const filterWalletId = textIncludes(item?.walletId, keySearch);

        const filterToken = item?.coinTokens?.filter((token) => {
          return textIncludes(token?.walletAddress, keySearch);
        });

        return filterWalletId || filterToken === '' || !isEmpty(filterToken);
      })
      .map((item, index) => ({
        index: index + 1,
        ...item,
      }));

  useEffect(() => {
    dispatch(getListAssetStart({}));
  }, [dispatch]);

  return (
    <div className="user-management asset-management">
      <PageHeaderComponent title="Assets Management" />
      <div className="search">
        <Input
          placeholder="Search"
          value={keySearch}
          onKeyDown={handleSearch}
          onChange={handleNameSearch}
          suffix={<IconSearch />}
          maxLength={256}
          onBlur={handleChangeBlur}
        />
      </div>
      <TableComponent dataSource={listAsset(assets)} columns={assetColumns() as any} rowKey="index" />
    </div>
  );
};

export default AssetPage;
