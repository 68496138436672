import { Button, Space } from "antd";
import classNames from "classnames";
import ModalComponent from "components/Modal";
import React from "react";
import { useState } from "react";

const useConfirm = ({ title, message, buttonCancelProps = {}, buttonConfirmProps = {}, modalProps = {} }) => {
  const [promise, setPromise] = useState(null) as any;

  const { text: textCancel, className: classNameCancel } = buttonCancelProps as {
    text: string;
    className?: string;
  };
  const { text: textConfirm, className: classNameConfirm } = buttonConfirmProps as {
    text: string;
    className?: string;
  };

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  // You could replace the Dialog with your library's version
  const ConfirmationDialog = () => (
    <ModalComponent
      title={title}
      visible={promise !== null}
      onClose={handleCancel}
      wrapClassName="modal-confirm"
      width={411}
      {...modalProps}
    >
      <div className="modal-confirm__message" dangerouslySetInnerHTML={{ __html: message }} />
      <Space
        size={14}
        className={classNames({
          "full-width": !(textCancel && textConfirm),
        })}
      >
        {textCancel && (
          <Button onClick={handleCancel} className={classNames("button button-primary-outline", classNameCancel)}>
            {textCancel}
          </Button>
        )}
        {textConfirm && (
          <Button onClick={handleConfirm} className={classNames("button button-cancel", classNameConfirm)}>
            {textConfirm}
          </Button>
        )}
      </Space>
    </ModalComponent>
  );
  return [ConfirmationDialog, confirm];
};

export default useConfirm;
