import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { useAppSelector } from '../../utils/customHooks';

function PrivateRoute(props: any) {
  const { component: Component, roles, title, ...rest } = props;
  const token = useAppSelector((state) => state.auth.currentUser.token);
  const role = useAppSelector((state) => state.auth.currentUser.role);

  useEffect(() => {
    document.title = title;
  }, [document]);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!token) {
          return <Redirect to="/login" />;
        }

        // if (roles && !roles.includes(role)) {
        //   return <Redirect to={{ pathname: "/" }} />;
        // }

        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
