import React from 'react';
import ModalComponent from 'components/Modal';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ModalProcessing = ({ visible }) => {
  return (
    <ModalComponent
      visible={visible}
      onClose={null}
      title="Processing request"
      showCloseIcon={false}
      wrapClassName="metamask-modal"
      width={500}
    >
      <Spin indicator={<LoadingOutlined />} />
      <div className="description">
        Your request is being processed. Please do not close the browser until the processing is over. Otherwise, the
        transaction might be failed.
      </div>
    </ModalComponent>
  );
};

export default ModalProcessing;
