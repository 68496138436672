import * as React from 'react';

function IconSucceeded(props: any) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_13_26861)">
        <path
          d="M32 60C24.5739 60 17.452 57.05 12.201 51.799C6.94999 46.548 4 39.4261 4 32C4 24.5739 6.94999 17.452 12.201 12.201C17.452 6.94999 24.5739 4 32 4C39.4261 4 46.548 6.94999 51.799 12.201C57.05 17.452 60 24.5739 60 32C60 39.4261 57.05 46.548 51.799 51.799C46.548 57.05 39.4261 60 32 60ZM32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64Z"
          fill="#4ECC79"
        />
        <path
          d="M43.8737 19.8697C43.8452 19.8973 43.8185 19.9267 43.7937 19.9577L29.9017 37.6577L21.5297 29.2817C20.961 28.7518 20.2089 28.4633 19.4317 28.477C18.6544 28.4907 17.9129 28.8056 17.3633 29.3552C16.8136 29.9049 16.4988 30.6464 16.485 31.4236C16.4713 32.2009 16.7598 32.953 17.2897 33.5217L27.8737 44.1097C28.1589 44.3943 28.4984 44.6186 28.8721 44.7691C29.2458 44.9197 29.6459 44.9934 30.0487 44.986C30.4515 44.9785 30.8487 44.89 31.2165 44.7257C31.5844 44.5614 31.9154 44.3247 32.1897 44.0297L48.1577 24.0697C48.7015 23.4991 48.9987 22.7373 48.9853 21.9492C48.9719 21.1611 48.6488 20.4099 48.086 19.8581C47.5231 19.3063 46.7657 18.9982 45.9775 19.0003C45.1892 19.0025 44.4335 19.3148 43.8737 19.8697Z"
          fill="#4ECC79"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_26861">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSucceeded;
