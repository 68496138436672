import { ethers } from 'ethers';

const useInjected = (onSucceeded?: (x?: any) => void, onFailed?: (x?: any) => void) => {
  const handleConnect = () => {
    if (!window.ethereum) {
      onFailed && onFailed('noMetamask');
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider
      .send('eth_requestAccounts', [])
      .then((accounts) => {
        if (accounts.length > 0) {
          onSucceeded && onSucceeded(accounts);
        }
      })
      .catch((e) => {
        onFailed && onFailed(e);
        console.log(e);
      });
  };

  return {
    handleConnect,
  };
};

export default useInjected;
