import api from './api';

export const listUserAPI = () => api.get('/coin-tokens');

export const getTokenInfoAPI = (payload: any) => api.get('/coin-tokens/info', payload);

export const getTokenDetailsAPI = (id: string) => api.get(`/coin-tokens/${id}`);

export const addTokenAPI = (payload: any) => api.post('/coin-tokens', payload);

export const editTokenAPI = (id: string, payload: any) => api.put(`/coin-tokens/${id}`, payload);

export const getListChainAPI = () => api.get('/config');

export const deleteTokenAPI = (id: string) => api.delete(`/coin-tokens/${id}`);
