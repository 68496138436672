import Layout, { Content } from 'antd/lib/layout/layout';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';

import { publicRoutes } from 'Routes';
import { getToken } from 'services/api';
import { useAppSelector } from 'utils/customHooks';
import LoadingComponent from 'components/Loading';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Logo from 'resources/images/logo.png';

export function PublicLayout() {
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.auth.currentUser.token);
  const location = useLocation();

  const isPasswordResetPage = location.pathname === '/reset';

  if (token) {
    getToken(token);

    return <Redirect to="/" />;
  }

  return (
    <Layout className={classnames('public layout-public', { 'public-layout-2': isPasswordResetPage })}>
      <Content>
        <img src={Logo} alt="Free token" className="logo" />
        <div className="box">
          <div className="box__content">
            <Suspense fallback={<LoadingComponent />}>
              <Switch>
                {publicRoutes.map((route: any) => (
                  <Route key={route.path} component={route.component} {...route} />
                ))}
              </Switch>
            </Suspense>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default React.memo(withRouter(PublicLayout));
