import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import authReducer from './auth/slice';
import userReducer from './user/slice';
import bannerReducer from './banner/slice';
import assetReducer from './asset/slice';
import lendingReducer from './leadingPool/slice';
import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['isForgotPassword'],
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  user: userReducer,
  banner: bannerReducer,
  asset: assetReducer,
  lendingPool: lendingReducer,
});
