import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  asset: {},
};

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    getListAssetStart: (state, action) => ({
      ...state,
    }),
    getListAssetSuccess: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    getAssetStart: (state, action) => ({
      ...state,
    }),
    getAssetSuccess: (state, action) => ({
      ...state,
      asset: {
        ...state.asset,
        [action.payload?._id]: action.payload,
      },
    }),
  },
});

// Action creators are generated for each case reducer function
export const { getListAssetStart, getListAssetSuccess, getAssetStart, getAssetSuccess } = assetSlice.actions;

export default assetSlice.reducer;
