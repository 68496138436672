import { Button, Menu, Tooltip } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { Space } from 'antd';
import React, { Suspense, useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import IconMenu from 'resources/images/IconMenu';
import HeaderCommon from 'components/Header';
import PrivateRoute from 'components/PrivateRoute';
import { privateRoutes } from 'Routes';
import LoadingComponent from 'components/Loading';
import Logo from 'resources/images/logo.png';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Item } = Menu;

function PrivateLayout(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const role = 'user';

  const toggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  const goToPage = (e: any) => {
    history.push(e.key);
  };

  const selectedKey = location.pathname.split('/')[1];

  const activeMenu = (data: any) => {
    const active = history.location?.pathname.split('/')[1] === data.split(['/'])[1];
    return active && 'ant-menu-item-selected';
  };

  return (
    <Layout style={{ minHeight: '100vh' }} className="layout-private">
      <Sider collapsed={collapsed} theme={'light'} className="sider" width={256}>
        <div className="sider__top">
          <Link to="/" className={'logo-page'}>
            {collapsed && (
              <img
                src={Logo}
                alt="Token Free"
                style={{ marginRight: collapsed ? '30px' : '0' }}
                className={'sider__text'}
              />
            )}
            {!collapsed && <img src={Logo} alt="Token Free" className={'sider__text'} />}
          </Link>
          <Button className="button-collapse-slider" onClick={toggleCollapse} icon={<IconMenu />} />
        </div>
        <Menu theme="light" selectedKeys={[selectedKey]} mode="inline">
          {privateRoutes(t).map(
            (item: any, index: number) =>
              (!item.roles || (item.roles && item.roles.includes(role))) && (
                <Fragment key={index}>
                  {!!item.inMenu && (
                    <Item
                      key={item.path}
                      icon={item.icon}
                      onClick={goToPage}
                      className={`${activeMenu(item.path)}`}
                      title={item.name}
                    >
                      {!collapsed && item.name}
                    </Item>
                  )}
                  {item?.subMenu && (
                    <SubMenu key={index} icon={item.icon} title={item.name}>
                      {item.subMenu.map((subItem: any, index: number) => (
                        <Menu.Item key={subItem.path} onClick={goToPage}>
                          {subItem.name}
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  )}
                </Fragment>
              )
          )}
        </Menu>
      </Sider>
      <Layout>
        <HeaderCommon />
        <Content>
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              {privateRoutes(t).map((route: any) => {
                if (route?.subMenu) {
                  return route.subMenu.map(
                    (r: any) => r.component && <PrivateRoute key={r.path} component={r.component} {...r} />
                  );
                }
                return route.component && <PrivateRoute key={route.path} component={route.component} {...route} />;
              })}
            </Switch>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default React.memo(withRouter(PrivateLayout));
