import { isBoolean } from 'lodash';
import { formatDateTable } from './../common/constant';
import moment from 'moment';
import { intersection } from 'lodash-es';
import { FORMAT_DATE, FORMAT_DATE_FULL, FORMAT_MINUTE_SECOND, SORT_ORDER, VALUE_NONE } from '../common/constant';
import i18n from '../i18n/i18n';
import BigNumber from 'bignumber.js';
import { RcFile } from 'antd/lib/upload';
BigNumber.config({
  EXPONENTIAL_AT: 100,
});
declare global {
  interface Window {
    ethereum: any;
  }
}

export const ConfigEnvironmentNetwork = {
  development: {
    chainId: 80001,
    chainIdHex: '0x13881',
    explorer: 'https://mumbai.polygonscan.com/tx/',
  },
  production: {
    chainId: 137,
    chainIdHex: '0x89',
    explorer: 'https://polygonscan.com/tx',
  },
};

export const ConfigNetwork = ConfigEnvironmentNetwork[process.env.REACT_APP_ENV!];

export function formatDate(date: moment.MomentInput, type = '') {
  return moment(date).format(type || FORMAT_DATE);
}

export function formatDateFull(date: moment.MomentInput, type = '') {
  return date ? moment(date).format(type || FORMAT_DATE_FULL) : VALUE_NONE;
}

export function getStartDateTimestamp(value: string) {
  return moment(value, FORMAT_DATE).startOf('days').utc().valueOf();
}

export function getEndDateTimestamp(value: string) {
  return moment(value, FORMAT_DATE).endOf('days').utc().valueOf();
}

export function dateNowNoSpecial() {
  return moment(new Date()).format('YYYYMMDDHHmmss');
}

export const getDurationSecondFromNow = (timeUnix: moment.MomentInput) => {
  const now = moment(new Date()); //now
  const b = moment(timeUnix);
  return now.diff(b, 'seconds');
};

export const formatTimeNumber = (number: number) => {
  return `0${number}`.slice(-2);
};

export const getTimeDistance2Dates = (endTime: any, startTime: any) => {
  const ms = moment(endTime).diff(moment(startTime));
  const d = moment.duration(ms);
  const floorHour = Math.floor(d.asHours());
  const hour = formatTimeNumber(floorHour);
  const s = hour + moment.utc(ms).format(FORMAT_MINUTE_SECOND);
  return s;
};

export const getDurationFromTimestamp = (time: any) => {
  if (time === 0) return VALUE_NONE;
  const d = moment.duration(time);
  const floorHour = Math.floor(d.asHours());
  const hour = formatTimeNumber(floorHour);
  const s = hour + moment.utc(time).format(FORMAT_MINUTE_SECOND);
  return s;
};

export const changeTable = (setFilters: (arg0: any) => void, filters: any) => ({
  handleChangePage: (page: any) => {
    setFilters({ ...filters, page });
  },
  handleChangeRowsPerPage: (pageSize: any) => {
    setFilters({
      ...filters,
      page: 0,
      pageSize,
    });
  },
  handleFilters: (data: any) => {
    setFilters({
      ...filters,
      ...data,
    });
  },
  handleSearch: (data: any) => {
    setFilters({
      ...filters,
      ...data,
    });
  },
});

export const compareShallowObject = (object1: any, object2: any) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export function getBase64(img: RcFile, callback: (arg0: string | ArrayBuffer | null) => any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const isFile = (input: any) => 'File' in window && input instanceof File;

export function isArrayWithLength(arr: string | any[]) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes: any, role: string) {
  return routes.filter(({ permission }: any) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, [role]).length;
  });
}

export function passwordStrength(pw: string): number {
  return (
    (/.{8,}/.test(pw) ? 1 : 0) /* at least 8 characters */ *
    ((/[a-z]/.test(pw) ? 1 : 0) /* a lower letter */ +
      (/[A-Z]/.test(pw) ? 1 : 0) /* a upper letter */ +
      (/\d/.test(pw) ? 1 : 0) /* a digit */ +
      (/[^A-Za-z0-9]/.test(pw) ? 1 : 0)) /* a special character */
  );
}

export function getExtension(filename: string) {
  const parts = filename?.split('.') || [];
  return parts[parts?.length - 1];
}

export function getFileName(filename: string) {
  return filename && filename.substring(0, filename.lastIndexOf('.'));
}
export const getOrderType = (order: string) => (order === 'ascend' && 1) || (order === 'descend' && -1) || '';

export const numberWithCommas = (number: number) => {
  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeFalsyElement = (data: any) =>
  Object.keys(data)
    .filter((key) => data[key] || data[key] === 0)
    .reduce((a, key) => ({ ...a, [key]: data[key] }), {});

export const convertAddress = (data: string) => {
  if (data) {
    const first = data.slice(0, 6);
    const last = data.slice(data.length - 10, data.length);
    return `${first}...${last}`;
  }

  return VALUE_NONE;
};

export const handleBlurField = (setFieldValue: any, field: string, handleBlur: any) => async (e: any) => {
  handleBlur(e);
  if (e.target.value) {
    setFieldValue(field, e.target.value.trim());
  }
};

export const convertAddressWallet = (value: string, num = 6) => {
  if (value) {
    return `${value.slice(0, num)}...${value.slice(-4)}`;
  }
  return '';
};

export const convertAddressToDisplayValue = (address: string) => {
  return address ? address.slice(0, 6) + '...' + address.slice(address.length - 4, address.length) : '';
};

export const range = (start: number, end: number) => {
  const result = [] as any;
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledStartDate = (endDate: any, isDisableBeforeToday?: boolean) => (current: any) => {
  return (
    current &&
    ((endDate && current > endDate.clone().endOf('day')) || (isDisableBeforeToday && current < moment().startOf('day')))
  );
};

export const disabledStartDateTime = (endDate: any) => (current: any) => {
  const now = endDate && endDate.clone().startOf('day');
  const hour = endDate && endDate.hours();
  const minutes = endDate && endDate.minutes();
  const seconds = endDate && endDate.seconds();

  const newStartDate = moment(current).clone().startOf('day');
  const hourStartDate = moment(current).hours();
  const minutesStartDate = moment(current).minutes();

  let disabledHours = [];
  let disabledMinutes = [];
  let disabledSeconds = [];

  if (newStartDate.isSame(now)) {
    disabledHours = range(minutes === 59 ? hour : hour + 1, 24);
    if (hourStartDate === hour) {
      disabledMinutes = range(minutes, 60);
    }
    if (minutesStartDate === minutes) {
      disabledSeconds = range(seconds, 60);
    }
  }

  return {
    disabledHours: () => disabledHours,
    disabledMinutes: () => disabledMinutes,
    disabledSeconds: () => disabledSeconds,
  };
};

export const disabledEndDate =
  (startDate, isDisableBeforeToday = false) =>
  (current) => {
    return (
      current &&
      (startDate
        ? current < startDate.clone().startOf('days')
        : isDisableBeforeToday
        ? current < moment().startOf('days')
        : null)
    );
  };

export const disabledEndDateTime = (startDate) => (current) => {
  const now = startDate && startDate.clone().startOf('day');
  const hour = startDate && startDate.hours();
  const minutes = startDate && startDate.minutes();
  const seconds = startDate && startDate.seconds();

  const newStartDate = moment(current).clone().startOf('day');
  const hourStartDate = moment(current).hours();
  const minutesStartDate = moment(current).minutes();

  let disabledHours = [];
  let disabledMinutes = [];
  let disabledSeconds = [];

  if (newStartDate.isSame(now)) {
    disabledHours = range(0, 24).slice(0, minutes === 59 ? hour - 1 : hour);
    if (hourStartDate === hour) {
      disabledMinutes = range(0, 60).slice(0, minutes);
    }
    if (minutesStartDate === minutes) {
      disabledSeconds = range(0, 60).slice(0, seconds);
    }
  }

  return {
    disabledHours: () => disabledHours,
    disabledMinutes: () => disabledMinutes,
    disabledSeconds: () => disabledSeconds,
  };
};

export const textIncludes = (string1: string, string2: string) => {
  if (!string1) {
    return false;
  }

  if (!string2) {
    return true;
  }

  return string1.toLowerCase().includes(string2?.toLowerCase());
};

export const limitMaxLengthNumber = (maxLength) => (inputObj) => {
  const { value } = inputObj;
  const integerPath = (value || '').split('.')[0];
  return integerPath.length <= maxLength;
};
