import React from 'react';
import ModalComponent from 'components/Modal';
import { Button } from 'antd';
import IconOpenTab from 'resources/images/IconOpenTab';
import IconFailed from 'resources/images/IconFailed';
import { ConfigNetwork } from 'utils';

const ModalFailed = ({ visible, onClose, txhash }) => {
  return (
    <ModalComponent visible={visible} onClose={onClose} title="Failed" wrapClassName="metamask-modal" width={500}>
      <IconFailed />
      <div className="description">Pool deployment failed. Please try again</div>
      {txhash && (
        <a
          href={`${ConfigNetwork.explorer}/${txhash}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button-explore"
        >
          <IconOpenTab />
          <span> View on PolygonScan</span>
        </a>
      )}
    </ModalComponent>
  );
};

export default ModalFailed;
