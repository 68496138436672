const STATUS = {
  DRAFT: 'draft',
  PROCESS_CREATE: 'process_create',
  PROCESS_UPDATE: 'process_update',
};

const LENDING_POOL_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DRAFT: 'draft',
  PROCESS_CREATE: 'process_create',
  PROCESS_UPDATE_APR: 'process_update_apr',
  ENDED: 'ended',
};

const LENDING_POOL_INTEREST_TYPE = {
  DAILY: 'daily',
  YEARLY: 'yearly',
};

const LENDING_TRANSACTION_TYPE = {
  CREATE_POOL: 'create-pool',
  UPDATE_APR: 'update-apr',
  UPDATE_POOL_STATUS: 'update-pool-status',
};

const SOCKET_CONNECT = 'connect';
const SOCKET_DISCONNECT = 'disconnect';
const SOCKET_NOTIFICATION_POOL = 'notification_pool';

export {
  STATUS,
  LENDING_POOL_STATUS,
  LENDING_POOL_INTEREST_TYPE,
  LENDING_TRANSACTION_TYPE,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_NOTIFICATION_POOL,
};
