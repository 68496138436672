import React from "react";

const IconBack = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M9 12.5L8.29289 11.7929L7.58579 12.5L8.29289 13.2071L9 12.5ZM14.2929 5.79289L8.29289 11.7929L9.70711 13.2071L15.7071 7.20711L14.2929 5.79289ZM8.29289 13.2071L14.2929 19.2071L15.7071 17.7929L9.70711 11.7929L8.29289 13.2071Z"
        fill="#3E43AA"
      />
    </svg>
  );
};

export default IconBack;
