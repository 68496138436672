import {
  addTokenAPI,
  listUserAPI,
  deleteTokenAPI,
  getTokenDetailsAPI,
  editTokenAPI,
  getListChainAPI,
} from './../../services/user';
import { HTTP_RESPONSE } from 'common/constant';
import { put, takeLatest } from 'redux-saga/effects';
import { ResponseGenerator } from '../../types';
import {
  getListTokenResponse,
  getListTokenRequest,
  handleDeleteTokenStart,
  addTokenStart,
  getTokenDetailsSuccess,
  getTokenDetailsStart,
  editTokenStart,
  getListChainSuccess,
  getListChainStart,
} from './slice';

function* getListTokenSaga() {
  try {
    const response: ResponseGenerator = yield listUserAPI();
    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getListTokenResponse(response.data));
    }
  } catch (error) {
    yield put(getListTokenResponse(error));
  }
}

function* getTokenDetailsSaga({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getTokenDetailsAPI(payload);
    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getTokenDetailsSuccess(response.data));
    }
  } catch (error) {}
}

function* addTokenSaga({ payload }: any) {
  const { data, callback } = payload;
  try {
    const response: ResponseGenerator = yield addTokenAPI(data);
    if (response?.status === HTTP_RESPONSE.CREATED) {
      callback(true);
    } else {
      callback(false, response?.data?.message);
    }
  } catch (error) {
    console.log(error);

    callback(false);
  }
}

function* editTokenSaga({ payload }: any) {
  const { id, data, callback } = payload;
  try {
    const response: ResponseGenerator = yield editTokenAPI(id, data);
    if (response?.status === HTTP_RESPONSE.OK) {
      callback(true);
    } else {
      callback(false, response?.data?.message);
    }
  } catch (error) {
    callback(false);
  }
}

function* getListChainSaga() {
  try {
    const response: ResponseGenerator = yield getListChainAPI();
    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getListChainSuccess(response.data?.chains));
    }
  } catch (error) {}
}

function* handleDeleteTokenSaga({ payload }) {
  const { id, callback } = payload;
  try {
    const response: ResponseGenerator = yield deleteTokenAPI(id);

    if (response?.status === HTTP_RESPONSE.OK) {
      callback(true);
    } else {
      callback(false, response?.data?.message);
    }
  } catch (error) {
    callback(false);
  }
}

function* watchUser() {
  yield takeLatest(getListTokenRequest, getListTokenSaga);
  yield takeLatest(addTokenStart, addTokenSaga);
  yield takeLatest(editTokenStart, editTokenSaga);
  yield takeLatest(getTokenDetailsStart, getTokenDetailsSaga);
  yield takeLatest(getListChainStart, getListChainSaga);
  yield takeLatest(handleDeleteTokenStart, handleDeleteTokenSaga);
}

export default watchUser;
