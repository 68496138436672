import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  user: [],
  token: {},
  chains: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getListTokenRequest: (state, action) => ({
      ...state,
    }),
    getListTokenResponse: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        user: payload,
      };
    },
    getTokenDetailsStart: (state, action) => ({
      ...state,
    }),
    getTokenDetailsSuccess: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        token: {
          [payload._id]: payload,
        },
      };
    },
    addTokenStart: (state, action) => ({
      ...state,
    }),
    editTokenStart: (state, action) => ({
      ...state,
    }),
    getListChainStart: (state, action) => ({
      ...state,
    }),
    getListChainSuccess: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        chains: payload,
      };
    },
    handleDeleteTokenStart: (state, action) => ({
      ...state,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  getListTokenRequest,
  getListTokenResponse,
  getTokenDetailsStart,
  getTokenDetailsSuccess,
  addTokenStart,
  editTokenStart,
  getListChainStart,
  getListChainSuccess,
  handleDeleteTokenStart,
} = userSlice.actions;

export default userSlice.reducer;
