import React from 'react';
import { Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const assetColumns = () => {
  return [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Wallet id',
      dataIndex: 'walletId',
      key: 'walletId',
      width: 400,
    },
    {
      title: 'Crypto assets',
      dataIndex: 'coinTokens',
      key: 'coinTokens',
      className: 'column-token',
      render: (tokens) =>
        (tokens || []).map(({ name, icon, walletAddress }, index) => (
          <Tooltip title={walletAddress} key={index}>
            <Space className="token">
              <span>{name}</span>
              <img src={icon} alt={name} className="icon-token" />
            </Space>
          </Tooltip>
        )),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      key: '_id',
      width: '100px',
      align: 'center',
      render: (id) => {
        return (
          <Space span={20}>
            <Link to={`/asset/${id}`}>
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </Space>
        );
      },
    },
  ];
};

export default assetColumns;
