import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_URL;

export default class Socket {
  socketIo;

  getInstance = (token) => {
    this.socketIo = io(`${URL}`, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
      transports: ['websocket'],
      auth: {
        token,
      },
    });

    return this.socketIo;
  };
}
