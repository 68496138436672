import React from 'react';
import ModalComponent from 'components/Modal';
import IconDownload from 'resources/images/IconDownload';
import LogoMetamask from 'resources/images/logo-metamask.jpg';

const ModalNoMetamask = ({ visible, onClose }) => {
  return (
    <ModalComponent
      visible={visible}
      onClose={onClose}
      title="Install MetaMask"
      wrapClassName="metamask-modal no-metamask-modal"
      width={500}
    >
      <div className="description">
        It seems that you have not installed MetaMask wallet service
        <br />
        Please install now
      </div>
      <img src={LogoMetamask} alt="Logo MetaMask" className="logo-metamask" />
      <a href="https://metamask.io/download/" target="_blank" className="button-download">
        <IconDownload />
        <span>Download MetaMask</span>
      </a>
    </ModalComponent>
  );
};

export default ModalNoMetamask;
