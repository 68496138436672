import { isBoolean } from 'lodash';

export const HTTP_RESPONSE = {
  OK: 200,
  CREATED: 201,
  ERROR_CODE_401: 401,
};

export const RESPONSE_STATUS = {
  SUCCESS: true,
  ERROR: false,
};

export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  IMG_DONE: 'done',
};

export const TYPE_NOTIFICATION = {
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
  P5: 'P5',
};

export const MAX_LENGTH_INPUT = 256;
export const MAX_LENGTH_TEXTAREA = 1000;
export const MAX_LENGTH_PASSWORD = 256;
export const MAX_LENGTH_INPUT_RESET = 10;

export const VALUE_NONE = '--';

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\w\W\d\s!-/:-@[-`{-~]{8,}$/;

export const REGEX_ALPHA_NUMBER = /^[A-Za-z0-9_-]+$/;

export const SLUG_CUTTING = /[a-zA-Z0-9_.-]+|\d+|\s+/gi;
//
export const SORT_ORDER = {
  ASCEND: 1,
  DESCEND: -1,
};

export const TYPE_INPUT = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  PASSWORD: 'PASSWORD',
  SELECT: 'SELECT',
  CHECKBOX: 'CHECKBOX',
  NUMBER: 'NUMBER',
  SEARCH: 'SEARCH',
  SELECT_INFINITY_SCROLL: 'SELECT_INFINITY_SCROLL',
  SWITCH: 'SWITCH',
  DATE: 'DATE',
  RADIO: 'RADIO',
  DRAGGER: 'DRAGGER',
};

export const PARAMS_TABLE = {
  limit: 10,
  offset: 0,
};

const PAGE_SIZE_DEFAULT = 10;
const PAGE_SIZE_OPTIONS = ['10', '20', '50'];
const INPUT_LENGTH = 256;

const STEP_FORGOT_PASSWORD = {
  CONFIRM_OTP_CODE: 'CONFIRM_OTP_CODE',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SUCCESS: 'SUCCESS',
};

const USER_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  BLOCKED: 2,
};

export const USER_ROLE = {
  USER: 1,
  ARTIST: 2,
  ADMIN: 3,
};

const FORMAT_DATE = 'DD-MM-YYYY';
const FORMAT_DATE_Y_M_D = 'YYYY/MM/DD';
const FORMAT_DATE_FULL = 'DD/MM/YYYY HH:mm:ss';
const FORMAT_MINUTE_SECOND = ':mm:ss';
const FORMAT_TIME = 'HH:mm:ss';

const DECIMAL_SCALE_NUMBER = 8;

const ROLE = {
  USER: 'user',
  ADMIN: 'admin',
};

const WEB_URL = process.env.REACT_APP_URL;

const LANGUAGE = {
  EN: 'en',
  VN: 'vi',
};

const LANGUAGE_DROPDOWN = {
  EN: {
    key: 'English',
    value: 'en',
  },
  VN: {
    key: 'Tiếng Việt',
    value: 'vi',
  },
};

const LENDING_POOL_CONTRACT_ADDRESS = process.env.REACT_APP_LENDING_POOL_CONTRACT_ADDRESS;

enum STATUS_ARTICLE {
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
}

export enum BANNER_RADIO {
  DEFAUL_BANNER = 'Default Banner',
  CUSTOM_BANNER = 'Custom Banner',
  DEFAUL_BANNER_NAME = 'common.default_banner',
  CUSTOM_BANNER_NAME = 'common.custom_banner',
}

export const STATUS_NEWS = {
  'article.published': 'Published',
  'article.draft': 'Draft',
};

enum STATUS_CATEGORY {
  INACTIVE = 1,
  ACTIVE = 2,
}

enum STATUS_EVENT {
  UP_COMING = 1,
  ONGOING = 2,
  ENDED = 3,
  CANCELLED = 4,
}

const STATUS_UPLOAD_IMAGE = {
  UPLOADING: 'uploading',
  DONE: 'done',
  DONE_S3: 'done_s3', // upload done in S3
};

export const KYCType = {
  PASSPORT: 'passport',
};

export const KYCStatusValue = {
  IN_REVIEW: 'in-review',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};
export const KYCStatus = {
  [KYCStatusValue.IN_REVIEW]: 'common.inreview_status',
  [KYCStatusValue.APPROVED]: 'common.approved_status',
  [KYCStatusValue.REJECTED]: 'common.rejected_status',
};

export const RegisterRequiredName = {
  YES: 'common.yes',
  NO: 'common.no',
};

export const RegisterRequired = {
  [RegisterRequiredName.YES]: true,
  [RegisterRequiredName.NO]: false,
};

export const FORM_EVENT = {
  ONLINE: 'Online',
  OFFLINE: 'Offline',
};

export {
  FORMAT_TIME,
  FORMAT_DATE_Y_M_D,
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_OPTIONS,
  FORMAT_DATE,
  FORMAT_DATE_FULL,
  FORMAT_MINUTE_SECOND,
  INPUT_LENGTH,
  STEP_FORGOT_PASSWORD,
  USER_STATUS,
  DECIMAL_SCALE_NUMBER,
  ROLE,
  WEB_URL,
  LANGUAGE,
  STATUS_ARTICLE,
  STATUS_CATEGORY,
  STATUS_EVENT,
  STATUS_UPLOAD_IMAGE,
  LANGUAGE_DROPDOWN,
  LENDING_POOL_CONTRACT_ADDRESS,
};

export const numberToRound = (num: number, fix: number, percent?: boolean) => {
  let index = -1;
  if (num) {
    index = num.toString().indexOf('e');

    let stringAfter = num.toString().slice(index);
    let stringPre = num.toString().slice(0, index);

    if (index >= 0) {
      return [
        percent
          ? Number(stringPre)
              .toFixed(2)
              .replace(/(\.0+|0+)$/, '')
          : Number(stringPre).toFixed(2),
        stringAfter,
      ];
    }
  }

  return [
    percent
      ? Number(num)
          .toFixed(fix)
          .replace(/(\.0+|0+)$/, '')
      : parseFloat(Number(num).toFixed(fix)),
    '',
  ];
};

export const publishText = {
  NOT_NOW: 'notNow',
  NOW: 'now',
  DATE: 'date',
};

export const formatDateTime = 'DD/MM/YYYY HH:mm:ss';
export const formatDateTimeApi = 'YYYY-MM-DD HH:mm:ss';
export const formatDateTable = 'YYYY-MM-DD';
export const formatTimeTable = 'HH:mm:ss';

export const getTimeSeconds = (datetime: any, datetime_: any) => {
  let data = 0;
  data = datetime && datetime_ && datetime.diff(datetime_, 'seconds');
  return data;
};

export const actionName = {
  1: 'Stake',
  2: 'Unstake',
  3: 'Claim',
};

export const deleteKeyNullOfObject = (object: any) => {
  let objectClone = { ...object };
  Object.keys(object).map((e) => {
    if (
      (!isBoolean(objectClone[e]) && !objectClone[e] && objectClone[e] !== 0) ||
      (Array.isArray(objectClone[e]) && !objectClone[e]?.length)
    )
      delete objectClone[e];
  });
  return objectClone;
};

export const DECIMAL_SCALE = 18;
