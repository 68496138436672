import { all } from 'redux-saga/effects';
import watchAuth from './auth/saga';
import watchUser from './user/saga';
import watchBanner from './banner/saga';
import watchAsset from './asset/saga';
import watchLending from './leadingPool/saga';

function* rootSaga() {
  yield all([watchAuth(), watchUser(), watchBanner(), watchAsset(), watchLending()]);
}
export default rootSaga;
