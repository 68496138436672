import React, { Suspense, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Route, Router, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import LoadingComponent from 'components/Loading';
import { PublicLayout, PrivateLayout } from 'pages/layout';
import NotFound from 'pages/notFound';
import { privateRoutes, publicRoutes } from 'Routes';
import { browserHistory } from 'utils/history';

import 'antd/dist/antd.css';
import './App.css';
import './sass/_app.scss';

function App() {
  const { t } = useTranslation();

  return (
    <div className="App bg-app">
      <ConfigProvider autoInsertSpaceInButton={false}>
        <Router history={browserHistory}>
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              <Route path={publicRoutes.map((route) => route.path)} component={PublicLayout} />
              <Route path={privateRoutes(t).map((route) => route.path)} component={PrivateLayout} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default withTranslation()(App);
