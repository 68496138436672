import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import moment from 'moment';
import { LENDING_POOL_STATUS } from './constants';

export const columns = ({ onDeleteToken }) => {
  return [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '50px',
    },
    {
      title: 'Pool ID',
      dataIndex: 'poolId',
      key: 'poolId',
      width: 90,
      render: (value, record) => (record?.txidCreate ? `LP-${value}` : '--'),
    },
    {
      title: 'Principal Token',
      dataIndex: 'lendingToken',
      key: 'lendingToken',
      sorter: true,
      width: 150,
      render: (value) => (
        <Space>
          <img src={value?.icon} alt={value?.name} width={30} />
          <span>{value?.name}</span>
        </Space>
      ),
    },
    {
      title: 'Interest Token',
      dataIndex: 'interestToken',
      key: 'interestToken',
      sorter: true,
      width: 150,
      render: (value) => (
        <Space>
          <img src={value?.icon} alt={value?.name} width={30} />
          <span>{value?.name}</span>
        </Space>
      ),
    },
    {
      title: 'APR',
      dataIndex: 'apr',
      key: 'apr',
      sorter: true,
      width: 90,
      render: (value) => `${value}%`,
    },
    {
      title: 'Payout Period',
      dataIndex: 'payoutPeriod',
      key: 'payoutPeriod',
      width: 120,
      className: 'payout-period',
    },
    {
      title: 'Principal Lock Duration',
      dataIndex: 'lendingLockingDuration',
      key: 'lendingLockingDuration',
      sorter: true,
      width: 150,
    },
    {
      title: 'Interest Lock Duration',
      dataIndex: 'interestLockingDuration',
      key: 'interestLockingDuration',
      sorter: true,
      width: 150,
    },
    {
      title: 'End Time',
      dataIndex: 'closeDate',
      key: 'closeDate',
      sorter: true,
      width: 120,
      render: (value) =>
        value ? (
          <>
            <span>{moment.utc(value).format('DD/MM/YYYY')}</span>
            <br />
            <span>{moment.utc(value).format('HH:mm:ss')}</span>
          </>
        ) : (
          '--'
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      className: 'column-status',
      width: 140,
      render: (value) => (
        <div
          className={classNames('pool-status', {
            'status-processing': value === 'process_create',
            'status-draft': value === 'draft',
            'status-ended': value === 'ended',
            'status-inactive': value === LENDING_POOL_STATUS.INACTIVE,
          })}
        >
          {['process_create', 'process_update'].includes(value)
            ? 'Processing'
            : value === LENDING_POOL_STATUS.INACTIVE
            ? 'Hide'
            : value}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      key: '_id',
      width: '100px',
      align: 'center',
      render: (id, record) => {
        return (
          <Space span={20}>
            <Link to={`/lending-pool/${id}`}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </Link>
            <Button
              icon={<FontAwesomeIcon icon={faTrash} />}
              className="delete-icon"
              onClick={onDeleteToken(id)}
              disabled={record?.status !== LENDING_POOL_STATUS.DRAFT}
            />
          </Space>
        );
      },
    },
  ];
};
