import React from 'react';
import { Button, Spin } from 'antd';
import ModalComponent from 'components/Modal';
import { LoadingOutlined } from '@ant-design/icons';
import { ConfigNetwork } from 'utils';

const ModalWrongNetwork = ({ visible }) => {
  const handleChangeNetwork = () => {
    if (!window.ethereum) return;

    window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: ConfigNetwork.chainIdHex,
        },
      ],
    });
  };

  return (
    <ModalComponent
      visible={visible}
      onClose={null}
      title="Wrong network"
      showCloseIcon={false}
      wrapClassName="metamask-modal"
      width={500}
    >
      <Spin indicator={<LoadingOutlined />} />
      <div className="description">
        Please change your wallet's network to <b>Polygon Mainnet</b> to continue
      </div>
      <Button type="primary" onClick={handleChangeNetwork}>
        Change network
      </Button>
    </ModalComponent>
  );
};

export default ModalWrongNetwork;
