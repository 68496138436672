import * as React from 'react';

function IconOpenTab(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_202_537)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.954 5.25C12.954 5.05109 12.875 4.86032 12.7343 4.71967C12.5937 4.57902 12.4029 4.5 12.204 4.5H2.25C1.65326 4.5 1.08097 4.73705 0.65901 5.15901C0.237053 5.58097 0 6.15326 0 6.75V21.75C0 22.3467 0.237053 22.919 0.65901 23.341C1.08097 23.7629 1.65326 24 2.25 24H17.25C17.8467 24 18.419 23.7629 18.841 23.341C19.2629 22.919 19.5 22.3467 19.5 21.75V11.796C19.5 11.5971 19.421 11.4063 19.2803 11.2657C19.1397 11.125 18.9489 11.046 18.75 11.046C18.5511 11.046 18.3603 11.125 18.2197 11.2657C18.079 11.4063 18 11.5971 18 11.796V21.75C18 21.9489 17.921 22.1397 17.7803 22.2803C17.6397 22.421 17.4489 22.5 17.25 22.5H2.25C2.05109 22.5 1.86032 22.421 1.71967 22.2803C1.57902 22.1397 1.5 21.9489 1.5 21.75V6.75C1.5 6.55109 1.57902 6.36032 1.71967 6.21967C1.86032 6.07902 2.05109 6 2.25 6H12.204C12.4029 6 12.5937 5.92098 12.7343 5.78033C12.875 5.63968 12.954 5.44891 12.954 5.25Z"
          fill="#242424"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0009 0.75C24.0009 0.551088 23.9219 0.360322 23.7812 0.21967C23.6406 0.0790176 23.4498 0 23.2509 0H15.7509C15.552 0 15.3612 0.0790176 15.2206 0.21967C15.0799 0.360322 15.0009 0.551088 15.0009 0.75C15.0009 0.948912 15.0799 1.13968 15.2206 1.28033C15.3612 1.42098 15.552 1.5 15.7509 1.5H21.4404L9.21992 13.719C9.15019 13.7887 9.09487 13.8715 9.05713 13.9626C9.01939 14.0537 8.99997 14.1514 8.99997 14.25C8.99997 14.3486 9.01939 14.4463 9.05713 14.5374C9.09487 14.6285 9.15019 14.7113 9.21992 14.781C9.28965 14.8507 9.37243 14.906 9.46354 14.9438C9.55465 14.9815 9.6523 15.0009 9.75092 15.0009C9.84953 15.0009 9.94718 14.9815 10.0383 14.9438C10.1294 14.906 10.2122 14.8507 10.2819 14.781L22.5009 2.5605V8.25C22.5009 8.44891 22.5799 8.63968 22.7206 8.78033C22.8612 8.92098 23.052 9 23.2509 9C23.4498 9 23.6406 8.92098 23.7812 8.78033C23.9219 8.63968 24.0009 8.44891 24.0009 8.25V0.75Z"
          fill="#242424"
        />
      </g>
      <defs>
        <clipPath id="clip0_202_537">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconOpenTab;
