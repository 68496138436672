import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';
import { Button, Col, Descriptions, Row, Tooltip } from 'antd';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectAsset } from 'redux/asset/selected';
import { getAssetStart } from 'redux/asset/slice';
import { useAppDispatch, useAppSelector } from 'utils/customHooks';
import NumberFormat from 'components/NumberFormat';
import PageHeaderComponent from 'components/PageHeader';

const { Item } = Descriptions;

const AssetDetailsPage = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const asset = useAppSelector(selectAsset(id));
  const [isLoading, setIsLoading] = useState(false);

  const handleGetAsset = (id: string) => {
    dispatch(getAssetStart(id));
  };

  const handleRefresh = () => {
    handleGetAsset(id);
    setIsLoading(true);
  };

  const gotoPage = (link) => () => {
    history.push(link);
  };

  useEffect(() => {
    handleGetAsset(id);
  }, [dispatch, id]);

  useEffect(() => {
    if (!isEmpty(asset)) {
      setIsLoading(false);
    }
  }, [asset]);

  return (
    <div className="asset-details">
      <PageHeaderComponent
        onBack={gotoPage('/asset')}
        title="Wallet Detail"
        extra={<Button icon={<FontAwesomeIcon icon={faRefresh} />} onClick={handleRefresh} loading={isLoading} />}
      />
      <div className="content">
        <Row>
          <Col md={4}>
            <label>Wallet id</label>
          </Col>
          <Col md={20}>{asset?.walletId || '--'}</Col>
          <Col md={4}>
            <label>Crypto assets</label>
          </Col>
          <Col md={20}>
            {asset?.coinTokens?.map(({ symbol, walletAddress, balance, icon }, index) => (
              <Tooltip key={index} title={walletAddress}>
                <div className="token">
                  <NumberFormat value={new BigNumber(balance).toNumber()} displayType="text" thousandSeparator />
                  <span>{symbol}</span>
                  <img src={icon} alt={symbol} className="token__icon" />
                </div>
              </Tooltip>
            ))}
            {!asset?.coinTokens && '--'}
          </Col>
          <Col md={4}>
            <label>Total assets</label>
          </Col>
          <Col md={20} className="total-asset">
            {!asset?.totalUsd && !asset?.totalJpy && '--'}
            <NumberFormat
              value={new BigNumber(asset?.totalUsd).toNumber()}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
            <NumberFormat
              value={new BigNumber(asset?.totalJpy).toNumber()}
              displayType="text"
              thousandSeparator
              prefix="¥"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AssetDetailsPage;
