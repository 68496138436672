import { PageHeader } from 'antd';
import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import IconBack from 'resources/images/Iconback';

type Props = {
  backLink?: string;
  onBack?: any;
  title: string | ReactNode;
  extra?: any;
  subTitleCustom?: ReactNode;
};

const PageHeaderComponent: FC<Props> = ({ backLink = '', onBack, title, subTitleCustom, ...props }) => {
  const history = useHistory();

  const handleBack = (backLink) => () => {
    history.push(backLink);
  };

  return (
    <PageHeader
      backIcon={<IconBack />}
      onBack={onBack || (backLink && handleBack(backLink))}
      title={
        <>
          {title}
          {subTitleCustom && <div className="subtitle">{subTitleCustom}</div>}
        </>
      }
      {...props}
    />
  );
};

export default PageHeaderComponent;
