import React from "react";

const IconUserManagement = (props: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99998 9.66667C11.8409 9.66667 13.3333 8.17428 13.3333 6.33333C13.3333 4.49238 11.8409 3 9.99998 3C8.15903 3 6.66665 4.49238 6.66665 6.33333C6.66665 8.17428 8.15903 9.66667 9.99998 9.66667Z"
        fill="#777E90"
      />
      <path
        d="M9.99998 11.3333C7.27362 11.3333 4.85305 12.6426 3.3327 14.6667C4.85305 16.6907 7.27362 18 9.99998 18C12.7263 18 15.1469 16.6907 16.6673 14.6667C15.1469 12.6426 12.7263 11.3333 9.99998 11.3333Z"
        fill="#777E90"
      />
    </svg>
  );
};

export default IconUserManagement;
