import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tokens: [],
};

export const leadingPoolSlice = createSlice({
  name: 'leadingPool',
  initialState,
  reducers: {
    getListTokenLendingRequest: (state, action) => ({
      ...state,
    }),
    getListTokenLendingResponse: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        tokens: payload,
      };
    },
    createLendingPoolRequest: (state, action) => ({
      ...state,
    }),
    editLendingPoolAPRRequest: (state, action) => ({
      ...state,
    }),
    getLendingPoolDetailsStart: (state, action) => ({
      ...state,
    }),
    getLendingPoolDetailsResponse: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        pool: {
          [payload?._id]: payload,
        },
      };
    },
    getListLendingPoolRequest: (state, action) => ({
      ...state,
    }),
    getListLendingPoolResponse: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        list: payload,
      };
    },
    deleteLendingPoolRequest: (state, action) => ({
      ...state,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  getListTokenLendingRequest,
  getListTokenLendingResponse,
  createLendingPoolRequest,
  getLendingPoolDetailsStart,
  getLendingPoolDetailsResponse,
  getListLendingPoolRequest,
  getListLendingPoolResponse,
  editLendingPoolAPRRequest,
  deleteLendingPoolRequest,
} = leadingPoolSlice.actions;

export default leadingPoolSlice.reducer;
