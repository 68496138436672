import { HTTP_RESPONSE } from 'common/constant';
import { put, takeLatest } from 'redux-saga/effects';
import { getAssetAPI, getListAssetAPI } from 'services/asset';
import { ResponseGenerator } from '../../types';
import { getAssetStart, getAssetSuccess, getListAssetStart, getListAssetSuccess } from './slice';

function* getListAsset({ payload }) {
  try {
    const response: ResponseGenerator = yield getListAssetAPI(payload);

    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getListAssetSuccess(response?.data));
    }
  } catch (error: any) {}
}

function* getAsset({ payload }) {
  try {
    const response: ResponseGenerator = yield getAssetAPI(payload);

    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getAssetSuccess(response?.data));
    }
  } catch (error: any) {}
}

function* watchAsset() {
  yield takeLatest(getListAssetStart, getListAsset);
  yield takeLatest(getAssetStart, getAsset);
}

export default watchAsset;
