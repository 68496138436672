import { HTTP_RESPONSE } from 'common/constant';
import { call, put, takeLatest } from 'redux-saga/effects';
import { changePasswordAPI, loginAPI } from '../../services/auth';
import { ResponseGenerator } from '../../types';
import { changePasswordStart, loginStart, loginSuccess } from './slice';

function* login({ payload }: any) {
  const { data, callback } = payload;
  try {
    const response: ResponseGenerator = yield loginAPI(data);

    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(loginSuccess(response.data));
    } else {
      yield call(callback, response?.message);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield call(callback);
  }
}

function* changePassword({ payload }: any) {
  const { data, onResponse } = payload;
  try {
    const response: ResponseGenerator = yield changePasswordAPI(data);
    if (response?.meta?.code === 0) {
      yield call(onResponse, true);
    } else {
      yield call(onResponse, false, response?.message);
    }
  } catch (error) {
    console.error(error);
    yield call(onResponse, false);
  }
}

function* watchAuth() {
  yield takeLatest(loginStart, login);
  yield takeLatest(changePasswordStart, changePassword);
}

export default watchAuth;
