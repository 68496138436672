import React, { FC, useMemo } from 'react';
import { Button, Pagination, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTIONS } from 'common/constant';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, GetRowKey, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import NoData from 'resources/images/No_data.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

type TableProps = {
  columns?: ColumnsType<any>;
  dataSource?: readonly any[];
  current?: number;
  pageSize?: number;
  total?: number;
  rowClassName?: string;
  pageSizeOptions?: string[];
  onChangeTable?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => void;
  onChangePagination?: (page: number, pageSize?: number) => void;
  size?: SizeType;
  bordered?: boolean;
  rowKey?: string | GetRowKey<any>;
  className?: string;
  scroll?: any;
  loading?: boolean;
  showTotal?: boolean;
  showSizeChanger?: boolean;
  showPagination?: boolean;
  sortDirections?: any;
  pagination?: any;
};

const TableComponent: FC<TableProps> = ({
  columns,
  dataSource,
  current,
  pageSize,
  total,
  rowClassName,
  pageSizeOptions,
  onChangeTable,
  onChangePagination,
  size,
  bordered = true,
  rowKey,
  className,
  scroll,
  loading = false,
  showTotal = true,
  showSizeChanger = false,
  showPagination = false,
  sortDirections,
  ...props
}) => {
  const { t } = useTranslation();

  const RenderEmptyData = useMemo(() => {
    return (
      <div className="ant-empty-text">
        <div className="img-no-data">
          <img src={NoData} alt="No Data" />
        </div>
        <p>{t('common.noData')}</p>
      </div>
    );
  }, [t]);

  const itemRender = (current: number, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <Button className="button-pagination" icon={<FontAwesomeIcon icon={faChevronLeft} />} />;
    }
    if (type === 'next') {
      return <Button className="button-pagination" icon={<FontAwesomeIcon icon={faChevronRight} />} />;
    }
    return originalElement;
  };

  return (
    <div className="table">
      <Table
        locale={{
          emptyText: loading ? (
            <div style={{ minHeight: '194px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
          ) : (
            RenderEmptyData
          ),
        }}
        pagination={{
          itemRender: itemRender,
          showSizeChanger: true,
          pageSizeOptions: pageSizeOptions ?? PAGE_SIZE_OPTIONS,
          current: current,
          pageSize: pageSize,
          total: total,
        }}
        columns={columns}
        bordered={bordered}
        rowClassName={rowClassName}
        dataSource={dataSource}
        onChange={onChangeTable}
        size={size}
        showSorterTooltip={false}
        rowKey={rowKey}
        className={className}
        scroll={scroll || { x: 960 }}
        loading={{
          spinning: loading,
          indicator: <Spin size="large" />,
        }}
        {...props}
      />
      {showPagination && (
        <div className="my-pagination">
          <Pagination
            size="small"
            total={total ? total : 0}
            current={current}
            onChange={onChangePagination}
            pageSizeOptions={pageSizeOptions ?? PAGE_SIZE_OPTIONS}
            pageSize={pageSize ?? PAGE_SIZE_DEFAULT}
            showSizeChanger={true}
            showQuickJumper={Boolean(total && pageSize && total > pageSize * 10)}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
