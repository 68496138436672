import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Form, Formik } from 'formik';
import { Button, Col, message, Row, Space } from 'antd';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';

import PageHeaderComponent from 'components/PageHeader';
import FormItem from 'components/FormItem';
import { FORMAT_DATE_FULL, LENDING_POOL_CONTRACT_ADDRESS, TYPE_INPUT } from 'common/constant';
import { useAppDispatch, useAppSelector } from 'utils/customHooks';
import { getListChainStart } from 'redux/user/slice';
import { selectChains } from 'redux/user/selected';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  createLendingPoolRequest,
  deleteLendingPoolRequest,
  editLendingPoolAPRRequest,
  getLendingPoolDetailsStart,
  getListTokenLendingRequest,
} from 'redux/leadingPool/slice';
import { selectLendingPoolsDetails, selectLendingTokens } from 'redux/leadingPool/selected';
import { ethers } from 'ethers';
import lendingPoolABI from 'abi/lendingPool.json';
import ModalProcessing from 'components/ModalMetamask/Processing';
import ModalSucceeded from 'components/ModalMetamask/Succeeded';
import ModalFailed from 'components/ModalMetamask/Failed';
import useInjected from 'hook/useInjected';
import { saveAddress } from 'redux/auth/slice';
import { selectAccessToken, selectAddress } from 'redux/auth/selected';
import classNames from 'classnames';
import { formatDateFull, limitMaxLengthNumber } from 'utils';
import useConfirm from 'hook/useConfirm';
import {
  LENDING_POOL_INTEREST_TYPE,
  LENDING_POOL_STATUS,
  LENDING_TRANSACTION_TYPE,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_NOTIFICATION_POOL,
  STATUS,
} from '../constants';
import Socket from 'socket';
import { formatCommasNumber } from 'components/NumberFormat/utils';

const { DRAFT, PROCESS_CREATE } = STATUS;
const {
  DRAFT: STATUS_DRAFT,
  ACTIVE: STATUS_ACTIVE,
  PROCESS_CREATE: STATUS_PROCESS_CREATE,
  PROCESS_UPDATE_APR: STATUS_PROCESS_UPDATE_APR,
  ENDED: STATUS_ENDED,
  INACTIVE: STATUS_INACTIVE,
} = LENDING_POOL_STATUS;
const { DAILY, YEARLY } = LENDING_POOL_INTEREST_TYPE;
const { CREATE_POOL, UPDATE_APR, UPDATE_POOL_STATUS } = LENDING_TRANSACTION_TYPE;

const getPoolIdExactLocation = () => {
  const inputString = window.location.pathname;
  const regex = /\/lending-pool\/([^/]+)/;
  const match = inputString.match(regex);

  if (match && match[1]) {
    const id = match[1];
    return id;
  } else {
    return null;
  }
};
const FormLendingPool = () => {
  const formikRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const isCreate = location.pathname.includes('create');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const chains = useAppSelector(selectChains);
  const tokens = useAppSelector(selectLendingTokens);
  const poolDetails = useAppSelector(selectLendingPoolsDetails(id));
  const address = useAppSelector(selectAddress);
  const accessToken = useAppSelector(selectAccessToken);
  const { handleConnect } = useInjected((accounts) => {
    dispatch(saveAddress(accounts[0]));
  });
  const isInActive = poolDetails?.status === STATUS_INACTIVE;
  const isDraft = !isCreate && poolDetails?.status === STATUS_DRAFT;
  const isActive = !isCreate && poolDetails?.status === STATUS_ACTIVE;
  const isProcessing = !isCreate && [STATUS_PROCESS_CREATE, STATUS_PROCESS_UPDATE_APR].includes(poolDetails?.status);
  const isEnded = !isCreate && poolDetails?.status === STATUS_ENDED;

  const [submitMode, setSubmitMode] = useState();
  const [initialValues, setInitialValues] = useState({
    chain: 'polygon',
    payoutPeriod: DAILY,
    lendingToken: null,
    interestToken: null,
    apr: '',
    lendingLockingDuration: null,
    interestLockingDuration: null,
    minimumLendingAmount: null,
    maximumLendingAmount: null,
    closeDate: null,
    status: PROCESS_CREATE,
    rateSum: 1,
  });
  const [lendingToken, setLendingToken] = useState('TOKEN');
  const [interestToken, setInterestToken] = useState('TOKEN');
  const [isLoading, setIsLoading] = useState(false);
  const [visibleProcessingModal, setVisibleProcessingModal] = useState(false);
  const [visibleSucceededModal, setVisibleSucceededModal] = useState(false);
  const [visibleFailedModal, setVisibleFailedModal] = useState(false);
  const [minimumLendingAmountDefault, setMinimumLendingAmountDefault] = useState(0);
  const [txhash, setTxhash] = useState('');
  const [poolId, setPoolId] = useState('');

  const draftValidationSchema = Yup.object().shape({
    lendingToken: Yup.number().nullable().required(t('message.E8')),
    interestToken: Yup.number().nullable().required(t('message.E8')),
    apr: Yup.number().required(t('message.E8')).moreThan(0, 'APR must be larger than 0'),
    lendingLockingDuration: Yup.number().when(['payoutPeriod'], (payoutPeriod) => {
      const payoutPeriodTime = payoutPeriod === DAILY ? 1 : 365;
      return Yup.number()
        .nullable()
        .required(t('message.E8'))
        .min(payoutPeriodTime, `Principal locking duration cannot be smaller than ${payoutPeriodTime}`);
    }),
  });

  const validationSchema = Yup.object()
    .shape(
      {
        chain: Yup.string().nullable().required(t('message.E8')),
        lendingToken: Yup.string().nullable().required(t('message.E8')),
        interestToken: Yup.string().nullable().required(t('message.E8')),
        apr: Yup.number().when(
          ['minimumLendingAmount', 'payoutPeriod', 'lendingToken', 'interestToken'],
          (min, payoutPeriod, lendingToken, interestToken, schema, value) => {
            const current = +value?.value || 0;

            const lendingTokenValue = tokens?.find((token) => token?.contractAddress === lendingToken);
            const interestTokenValue = tokens?.find((token) => token?.contractAddress === interestToken);

            if (lendingTokenValue && interestTokenValue && isActive) {
              const payoutPeriodTime = payoutPeriod !== DAILY ? 1 : 365;
              const maxValue = BigNumber.max(
                BigNumber(10)
                  .pow(-(lendingTokenValue?.decimals || 1))
                  .dividedBy(min),
                BigNumber(10)
                  .pow(-(interestTokenValue?.decimals || 1))
                  .dividedBy(min)
                  .multipliedBy(
                    (lendingTokenValue?.price?.usd / interestTokenValue?.price?.usd).toFixed(
                      lendingTokenValue?.decimals || 0
                    )
                  )
              )
                .multipliedBy(payoutPeriodTime)
                .multipliedBy(100)
                .decimalPlaces(1);

              if (current && BigNumber(current).isLessThan(maxValue)) {
                return Yup.number().test('apr', 'message', function () {
                  return this.createError({
                    path: 'apr',
                    message: `APR cannot be smaller than ${maxValue}%`,
                  });
                });
              }
            }

            return Yup.number().required(t('message.E8')).moreThan(0, 'APR must be larger than 0');
          }
        ),
        lendingLockingDuration: Yup.number()
          .nullable()
          .when(
            ['closeDate', 'interestLockingDuration', 'payoutPeriod'],
            (closeDate, interestLockingDuration, payoutPeriod, schema, value) => {
              const lendingLockingDuration = +value?.value * 24 * 60 * 60;
              if (!+value?.value && interestLockingDuration) {
                return Yup.number()
                  .required(t('message.E8'))
                  .moreThan(
                    interestLockingDuration,
                    'Principle locking duration cannot be smaller than Interest Locking Duration'
                  );
              }

              if (closeDate) {
                const now = moment(new Date()).unix();
                const closeDateTime = moment(closeDate).unix();
                if (lendingLockingDuration > closeDateTime - now) {
                  return Yup.number().test('lendingLockingDuration', 'message', function () {
                    return this.createError({
                      path: 'lendingLockingDuration',
                      message: 'Principle locking duration cannot be larger than than Pool End Time - Now',
                    });
                  });
                }
              }

              const payoutPeriodTime = payoutPeriod === DAILY ? 1 : 365;
              return Yup.number()
                .nullable()
                .required(t('message.E8'))
                .min(payoutPeriodTime, `Principle locking duration cannot be smaller than ${payoutPeriodTime}`);
            }
          ),
        interestLockingDuration: Yup.number().when(['lendingLockingDuration'], (lendingLockingDuration) => {
          return lendingLockingDuration
            ? Yup.number()
                .nullable()
                .max(
                  lendingLockingDuration,
                  'Interest locking duration must be smaller than Principle locking duration'
                )
            : Yup.number().nullable().notRequired();
        }),
        minimumLendingAmount: Yup.number().when(
          ['maximumLendingAmount', 'apr', 'payoutPeriod', 'lendingToken', 'interestToken'],
          (max, apr, payoutPeriod, lendingToken, interestToken, schema, value) => {
            const current = +value?.value || 0;
            if (max && !current) {
              return Yup.number()
                .nullable()
                .moreThan(0, 'Minium lending amount must be larger than 0')
                .max(max, 'Minimum lending amount cannot be larger than Maximum total value locked');
            }

            const lendingTokenValue = tokens?.find((token) => token?.contractAddress === lendingToken);
            const interestTokenValue = tokens?.find((token) => token?.contractAddress === interestToken);

            if (lendingTokenValue && interestTokenValue && !isActive) {
              const payoutPeriodTime = payoutPeriod !== DAILY ? 1 : 365;
              const maxValue = BigNumber.max(
                BigNumber(10).pow(-(lendingTokenValue?.decimals || 1)),
                BigNumber(10)
                  .pow(-(lendingTokenValue?.decimals || 1))
                  .dividedBy(BigNumber(apr * 0.01).dividedBy(payoutPeriodTime)),
                BigNumber(10)
                  .pow(-(interestTokenValue?.decimals || 1))
                  .dividedBy(BigNumber(apr * 0.01).dividedBy(payoutPeriodTime))
                  .multipliedBy(
                    (lendingTokenValue?.price?.usd / interestTokenValue?.price?.usd).toFixed(
                      lendingTokenValue?.decimals || 0
                    )
                  )
              );

              if (current && BigNumber(current).isLessThan(maxValue)) {
                return Yup.number().test('minimumLendingAmount', 'message', function () {
                  return this.createError({
                    path: 'minimumLendingAmount',
                    message: `Minimum lending amount cannot be smaller than ${maxValue}`,
                  });
                });
              }
            }

            return Yup.number().nullable().moreThan(0, 'Minimum lending amount must be larger than 0');
          }
        ),
        maximumLendingAmount: Yup.number()
          .nullable()
          .positive()
          .when(['minimumLendingAmount'], (min) => {
            return min
              ? Yup.number()
                  .nullable()
                  .min(min, 'Maximum total value locked cannot be smaller than Minimum lending amount')
                  .required('Maximum total value locked is required')
              : Yup.number()
                  .nullable()
                  .moreThan(0, 'Maximum total value locked must be larger than 0')
                  .required('Maximum total value locked is required');
          }),
        closeDate: Yup.mixed().required('Pool end time is required'),
      },
      [
        ['lendingLockingDuration', 'closeDate'],
        ['payoutPeriod', 'closeDate'],
        ['lendingLockingDuration', 'interestLockingDuration'],
        ['lendingLockingDuration', 'payoutPeriod'],
        ['minimumLendingAmount', 'lendingToken'],
        ['minimumLendingAmount', 'interestToken'],
        ['minimumLendingAmount', 'payoutPeriod'],
        ['minimumLendingAmount', 'apr'],
        ['minimumLendingAmount', 'maximumLendingAmount'],
      ]
    )
    .test('closeDate', 'message', function (value) {
      const { lendingLockingDuration, closeDate, payoutPeriod } = value;
      const payoutPeriodTime = (payoutPeriod === DAILY ? 1 : 365) * 24 * 60 * 60;
      const maxDiff = Math.max(lendingLockingDuration * 24 * 60 * 60, payoutPeriodTime);
      const maxField =
        lendingLockingDuration * 24 * 60 * 60 > payoutPeriodTime ? 'Principal Locking duration' : 'Payout Period';

      if (closeDate) {
        const now = moment(new Date()).unix();
        const closeDateTime = moment(closeDate).unix();
        if (closeDateTime < now + maxDiff) {
          return this.createError({
            path: 'closeDate',
            message: `Pool end date cannot be earlier than Now + ${maxField}`,
          });
        }
      }
    });

  const closeProcessingModal = () => {
    setVisibleProcessingModal(false);
  };

  const openProcessingModal = () => {
    setVisibleProcessingModal(true);
  };

  const closeSucceededModal = () => {
    setVisibleSucceededModal(false);
  };

  const openSucceededModal = () => {
    setVisibleSucceededModal(true);
  };

  const closeFailedModal = () => {
    setVisibleFailedModal(false);
  };

  const openFailedModal = () => {
    setVisibleFailedModal(true);
  };

  const gotoPage = (link) => {
    history.push(link);
  };

  const [ModalConfirmDelete, onConfirmDelete] = useConfirm({
    title: 'Confirmation',
    message: `Do you really want to delete the ${
      poolDetails?.lendingToken?.name ? `${poolDetails?.lendingToken?.name}/${poolDetails?.interestToken?.name}` : ''
    } pool?`,
    buttonCancelProps: {
      text: 'No',
    },
    buttonConfirmProps: {
      text: 'Yes',
      className: 'button-save',
    },
    modalProps: {
      width: 441,
    },
  });

  const [ModalConfirmHidePool, onConfirmHidePool] = useConfirm({
    title: 'Warning',
    message: "Once you hide the pool, you won't be able to activate it again!",
    buttonCancelProps: {
      text: 'No',
    },
    buttonConfirmProps: {
      text: 'Yes',
      className: 'button-save',
    },
    modalProps: {
      width: 441,
    },
  });

  const handleResponseCreateLendingPool = (status, errorMessage) => {
    if (status) {
      message.success('Created pool successfully');
      gotoPage('/lending-pool');
    } else {
      message.error('Saving was unsuccessful');
    }
    setIsLoading(false);
  };

  const handleDraftSubmit = (values) => {
    const {
      lendingLockingDuration,
      interestLockingDuration,
      apr,
      minimumLendingAmount,
      maximumLendingAmount,
      closeDate,
    } = values;
    const newValues = {
      ...values,
      apr: +apr * 10,
      lendingLockingDuration: +lendingLockingDuration || 0,
      interestLockingDuration: +interestLockingDuration || 0,
      minimumLendingAmount: +minimumLendingAmount || minimumLendingAmountDefault,
      maximumLendingAmount: +maximumLendingAmount || 0,
      closeDate: closeDate ? formatDateFull(closeDate) : null,
    };

    dispatch(
      createLendingPoolRequest({
        data: !isCreate
          ? {
              ...newValues,
              status: DRAFT,
              id: id,
            }
          : {
              ...newValues,
              status: DRAFT,
            },
        callback: handleResponseCreateLendingPool,
      })
    );
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    openProcessingModal();
    const {
      lendingLockingDuration,
      interestLockingDuration,
      minimumLendingAmount,
      maximumLendingAmount,
      apr,
      payoutPeriod,
      closeDate,
    } = values;

    const newValues = {
      ...values,
      apr: +apr * 10,
      closeDate: closeDate ? formatDateFull(closeDate) : null,
      lendingLockingDuration: +lendingLockingDuration || 0,
      interestLockingDuration: +interestLockingDuration || 0,
      minimumLendingAmount: minimumLendingAmount || minimumLendingAmountDefault,
      maximumLendingAmount: maximumLendingAmount || 0,
    };

    const lendingToken = tokens?.find((token) => token?.contractAddress === values?.lendingToken);
    const interestToken = tokens?.find((token) => token?.contractAddress === values?.interestToken);
    const rateSum = Math.ceil(
      Math.pow(10, interestToken?.decimals) * (interestToken?.price?.usd / lendingToken?.price?.usd)
    ).toString();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send('eth_requestAccounts', []);
    const signer = provider.getSigner();
    const lendingPool = new ethers.Contract(LENDING_POOL_CONTRACT_ADDRESS, lendingPoolABI, signer);

    if (isCreate || isDraft) {
      lendingPool
        .createPool(
          values?.lendingToken,
          values?.interestToken,
          newValues?.apr,
          BigNumber(10)
            .pow(lendingToken?.decimals || 1)
            .multipliedBy(newValues?.minimumLendingAmount)
            .integerValue(BigNumber.ROUND_CEIL)
            .toString(),
          BigNumber(10)
            .pow(lendingToken?.decimals || 1)
            .multipliedBy(newValues?.maximumLendingAmount)
            .toString(),
          newValues?.interestLockingDuration * 24 * 60 * 60,
          newValues?.lendingLockingDuration * 24 * 60 * 60,
          closeDate ? moment(closeDate).unix() : 0,
          rateSum,
          payoutPeriod === DAILY
        )
        .then((tx) => {
          setTxhash(tx.hash);

          dispatch(
            createLendingPoolRequest({
              data: {
                ...newValues,
                txidCreate: tx.hash,
                status: STATUS.PROCESS_CREATE,
              },
              callback: (status) => {
                if (!status) {
                  message.error('Saving was unsuccessful');
                  setIsLoading(false);
                  closeProcessingModal();
                }
              },
            })
          );
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          closeProcessingModal();
          openFailedModal();
        });
    } else if (isActive) {
      lendingPool
        .setAPY(newValues?.poolId, newValues?.apr, rateSum)
        .then((tx) => {
          setTxhash(tx.hash);
          dispatch(
            editLendingPoolAPRRequest({
              data: {
                id: poolDetails?._id,
                apr: newValues?.apr,
              },
              callback: (status) => {
                if (!status) {
                  message.error('Saving was unsuccessful');
                  setIsLoading(false);
                  closeProcessingModal();
                }
              },
            })
          );
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          closeProcessingModal();
          openFailedModal();
        });
    }
  };

  const handleResponseDeleteToken = (status, errorMessage) => {
    if (status) {
      message.success('Delete pool successfully');
      gotoPage('/lending-pool');
    } else {
      message.error(errorMessage || 'Deletion was unsuccessful');
    }
  };

  const handleDeletePool = async () => {
    const answer = await onConfirmDelete();

    if (answer) {
      dispatch(
        deleteLendingPoolRequest({
          id,
          callback: handleResponseDeleteToken,
        })
      );
    }
  };

  const handleHidePool = async () => {
    const answer = await onConfirmHidePool();
    if (answer) {
      await onHidePool();
    }
  };

  const onHidePool = async () => {
    try {
      setIsLoading(true);
      openProcessingModal();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const lendingpoolContract = new ethers.Contract(LENDING_POOL_CONTRACT_ADDRESS, lendingPoolABI, signer);

      await lendingpoolContract.setPoolStatus(+poolDetails?.poolId, false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      closeProcessingModal();
    }
  };

  useEffect(() => {
    const socket = new Socket().getInstance(accessToken);

    const onConnect = () => {
      console.log('connected');
    };

    const onDisconnect = () => {
      console.log('disconnected');
    };

    const handleEvent = (data) => {
      if (data?.result) {
        setIsLoading(false);
        closeProcessingModal();
        if (data?.type === CREATE_POOL) {
          openSucceededModal();
          setPoolId(data?.id);
        } else if (data?.type === UPDATE_APR) {
          openSucceededModal();
        } else if (data?.type === UPDATE_POOL_STATUS) {
          message.success('Hide pool successful');
          gotoPage('/lending-pool')
        } else {
          message.error('Saving was unsuccessful');
        }
      }
    };

    socket.on(SOCKET_CONNECT, onConnect);
    socket.on(SOCKET_DISCONNECT, onDisconnect);
    socket.on(SOCKET_NOTIFICATION_POOL, handleEvent);

    return () => {
      socket.removeAllListeners();
    };
  }, [accessToken]);

  useEffect(() => {
    if (isEmpty(chains)) {
      dispatch(getListChainStart());
    }

    dispatch(
      getListTokenLendingRequest({
        chain: 'polygon',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isCreate && poolDetails) {
      setInitialValues((prevState) => ({
        ...prevState,
        ...poolDetails,
        id: poolDetails?._id,
        lendingToken: poolDetails?.lendingToken?.contractAddress,
        interestToken: poolDetails?.interestToken?.contractAddress,
        closeDate: poolDetails?.closeDate ? moment.utc(poolDetails.closeDate) : '',
      }));
      setPoolId(poolDetails?._id);
      setLendingToken(poolDetails?.lendingToken);
      setInterestToken(poolDetails?.interestToken);
    }
  }, [isCreate, JSON.stringify(poolDetails)]);

  useEffect(() => {
    if (!isCreate && id) {
      dispatch(getLendingPoolDetailsStart(id));
    }
  }, [dispatch, isCreate, id]);

  return (
    <div className="form-lending-pool">
      <PageHeaderComponent
        title={isCreate ? 'Add Lending Pool' : `Lending Pool ${`LP-${poolDetails?.poolId}` || '--'}`}
        subTitleCustom={
          !isCreate &&
          `Created at ${formatDateFull(poolDetails?.createdAt)}` +
            (isActive || isProcessing || isEnded
              ? ` | Deployed on blockchain at ${formatDateFull(poolDetails?.deployedAt)}` +
                (poolDetails?.closeDate ? ` | Ends at ${formatDateFull(poolDetails?.closeDate)}` : '')
              : '')
        }
        onBack={() => gotoPage('/lending-pool')}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={submitMode === PROCESS_CREATE ? handleSubmit : handleDraftSubmit}
        validationSchema={submitMode === PROCESS_CREATE ? validationSchema : draftValidationSchema}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ values, handleSubmit }) => {
          const {
            apr,
            payoutPeriod,
            lendingToken: lendingTokenContractAddress,
            interestToken: interestTokenContractAddress,
          } = values;
          const lendingTokenValue = tokens?.find((token) => token?.contractAddress === lendingTokenContractAddress);
          const interestTokenValue = tokens?.find((token) => token?.contractAddress === interestTokenContractAddress);

          if (lendingTokenValue && interestTokenValue && apr) {
            const payoutPeriodTime = payoutPeriod !== DAILY ? 1 : 365;
            const maxValue = BigNumber.max(
              BigNumber(10).pow(-(lendingTokenValue?.decimals || 1)),
              BigNumber(10)
                .pow(-(lendingTokenValue?.decimals || 1))
                .dividedBy(BigNumber(apr * 0.01).dividedBy(payoutPeriodTime)),
              BigNumber(10)
                .pow(-(interestTokenValue?.decimals || 1))
                .dividedBy(BigNumber(apr * 0.01).dividedBy(payoutPeriodTime))
                .multipliedBy(
                  (lendingTokenValue?.price?.usd / interestTokenValue?.price?.usd).toFixed(
                    lendingTokenValue?.decimals || 0
                  )
                )
            );
            setMinimumLendingAmountDefault(maxValue?.toString());
          }

          return (
            <Form>
              {(isActive || isEnded) && (
                <div className="section-pool-operation">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className="section-title">POOL OPERATION</div>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        name="totalInterestReserved"
                        typeInput={TYPE_INPUT.TEXT}
                        options={chains}
                        label="Total interest reserved"
                        disabled
                        value={formatCommasNumber(poolDetails?.totalInterestReserved)}
                        prefix={
                          <img
                            src={poolDetails?.interestToken?.icon}
                            alt={poolDetails?.interestToken?.name}
                            width={30}
                          />
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <FormItem
                        name="totalInterest"
                        typeInput={TYPE_INPUT.TEXT}
                        label="Claimable interest (Present)"
                        description={`Next 30 day (est.): ${
                          poolDetails?.totalInterestFuture ? formatCommasNumber(poolDetails.totalInterestFuture) : 0
                        }`}
                        disabled
                        value={formatCommasNumber(poolDetails?.totalInterest)}
                        prefix={
                          <img
                            src={poolDetails?.interestToken?.icon}
                            alt={poolDetails?.interestToken?.name}
                            width={30}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>
              )}
              <div className="section-general">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className="section-title">GENERAL INFO</div>
                  </Col>
                  {(isActive || isEnded) && (
                    <>
                      <Col span={12}>
                        <FormItem name="poolId" label="Pool ID" disabled value={`LP-${values?.poolId}`} />
                      </Col>
                      <Col span={8}>
                        <FormItem
                          name="contractAddress"
                          label="Contract address"
                          disabled
                          value={LENDING_POOL_CONTRACT_ADDRESS}
                        />
                      </Col>
                    </>
                  )}
                  {!isCreate && (
                    <Col span={isDraft || isProcessing ? 24 : 4}>
                      <FormItem
                        name="status"
                        label="Status"
                        disabled
                        containerClassName={classNames('text-status', {
                          'status-active': isActive,
                          'status-draft': isDraft,
                          'status-processing': isProcessing,
                          'status-ended': isEnded,
                          'status-inactive': isInActive,
                        })}
                        value={
                          [STATUS_PROCESS_CREATE, STATUS_PROCESS_UPDATE_APR].includes(values.status)
                            ? 'Processing'
                            : values.status === STATUS_INACTIVE
                            ? 'Hide'
                            : values.status
                        }
                      />
                    </Col>
                  )}
                  <Col span={12}>
                    <FormItem
                      name="chain"
                      typeInput={TYPE_INPUT.SELECT}
                      options={chains}
                      label="Network"
                      required
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name="lendingToken"
                      typeInput={TYPE_INPUT.SELECT}
                      options={tokens
                        ?.map((token) => ({
                          name: token?.name,
                          value: token?.contractAddress,
                          imgUrl: token?.icon,
                          description: token?.contractAddress,
                          disabled: token?.status !== 'active' || !token?.isLendingCoin,
                        }))
                        ?.filter((token) => (isCreate ? !token?.disabled : true))}
                      label="Principal token"
                      placeholder="Select token"
                      required
                      disabled={!isCreate && !isDraft}
                      optionLabelProp="label"
                      dropdownClassName="lending-pool"
                      onChange={({ val }) => {
                        const lendingToken = tokens?.find((token) => token?.contractAddress === val);
                        setLendingToken(lendingToken);
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="section-interest-payout">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className="section-title">Interest payout</div>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <FormItem
                          name="interestToken"
                          typeInput={TYPE_INPUT.SELECT}
                          optionLabelProp="label"
                          options={tokens
                            ?.map((token) => ({
                              name: token?.name,
                              value: token?.contractAddress,
                              imgUrl: token?.icon,
                              description: token?.contractAddress,
                              disabled: token?.status !== 'active' || !token?.isLendingCoin,
                            }))
                            ?.filter((token) => (isCreate ? !token?.disabled : true))}
                          label="Interest token"
                          placeholder="Select token"
                          required
                          disabled={!isCreate && !isDraft}
                          dropdownClassName="lending-pool"
                          onChange={({ val }) => {
                            const interestToken = tokens?.find((token) => token?.contractAddress === val);
                            setInterestToken(interestToken);
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name="apr"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="APR (%)"
                          placeholder="Enter APR"
                          required
                          unit="%"
                          disabled={!isCreate && !isDraft && !isActive}
                          decimalScale={1}
                          isAllowed={limitMaxLengthNumber(4)}
                        />
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name="payoutPeriod"
                          typeInput={TYPE_INPUT.RADIO}
                          options={[
                            {
                              name: 'Daily',
                              value: DAILY,
                            },
                            {
                              name: 'Yearly',
                              value: YEARLY,
                            },
                          ]}
                          label="Interest payout period"
                          required
                          disabled={!isCreate && !isDraft}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="section-pool-bindings">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className="section-title">POOL BINDINDS</div>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <FormItem
                          name="lendingLockingDuration"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="Principal locking duration"
                          required
                          placeholder="Enter value"
                          unit="days"
                          maxLength={8}
                          decimalScale={0}
                          disabled={!isCreate && !isDraft}
                          description="Must be at least 1 day for daily pools or 365 days for yearly pool"
                        />
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name="interestLockingDuration"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="Interest locking duration"
                          placeholder="Enter value"
                          unit="days"
                          maxLength={8}
                          decimalScale={0}
                          disabled={!isCreate && !isDraft}
                        />
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name="minimumLendingAmount"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="Minimum lending amount"
                          placeholder="Enter value"
                          unit={lendingToken?.name || 'TOKEN'}
                          description="If not specified, minimum lending amount will be the decided by the decimal of principal and interest tokens to make sure pool operates normally"
                          decimalScale={50}
                          disabled={!isCreate && !isDraft}
                        />
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name="maximumLendingAmount"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="Maximum total value locked"
                          placeholder="Enter value"
                          unit={lendingToken?.name || 'TOKEN'}
                          disabled={!isCreate && !isDraft}
                          required
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <FormItem
                      typeInput={TYPE_INPUT.DATE}
                      name="closeDate"
                      label="Pool end time"
                      placeholder="dd/mm/yyyy hh:mm:ss"
                      format={FORMAT_DATE_FULL}
                      showTime
                      disabled={!isCreate && !isDraft}
                      required
                    />
                  </Col>
                </Row>
              </div>
              <Space size={10} className="actions">
                <Button onClick={() => gotoPage('/lending-pool')} className="button button-cancel">
                  Back
                </Button>

                {isCreate && (
                  <Button
                    onClick={async () => {
                      await setSubmitMode(DRAFT);
                      handleSubmit();
                    }}
                    className="button"
                  >
                    Save as draft
                  </Button>
                )}
                {isDraft && (
                  <Button
                    onClick={async () => {
                      await setSubmitMode(DRAFT);
                      handleSubmit();
                    }}
                    className="button"
                  >
                    Save changes
                  </Button>
                )}
                {!isEnded && !isProcessing && (
                  <>
                    {!address && (
                      <Button
                        className="button button-confirm"
                        onClick={() => {
                          handleConnect();
                        }}
                        loading={isLoading}
                      >
                        Connect MetaMask
                      </Button>
                    )}
                    {!!address && !isInActive && (
                      <Button
                        className="button button-confirm"
                        onClick={async () => {
                          await setSubmitMode(PROCESS_CREATE);
                          handleSubmit();
                        }}
                        loading={isLoading}
                      >
                        {isActive ? 'Save changes' : 'Deploy on chain'}
                      </Button>
                    )}
                  </>
                )}
                <Button
                  className={classNames('button button-cancel button-delete', {
                    hide: !isDraft,
                  })}
                  loading={isLoading}
                  onClick={handleDeletePool}
                >
                  Delete
                </Button>

                {!!address && isActive && poolDetails?.poolId && (
                  <Button
                    className={classNames('button button-cancel button-delete')}
                    loading={isLoading}
                    onClick={handleHidePool}
                  >
                    Hide Pool
                  </Button>
                )}
              </Space>
            </Form>
          );
        }}
      </Formik>
      <ModalProcessing visible={visibleProcessingModal} />
      <ModalSucceeded
        visible={visibleSucceededModal}
        token={`${lendingToken?.name}/${interestToken?.name}`}
        onClose={() => {
          const pid = poolId || poolDetails?._id;
          if (pid) {
            if (isCreate) {
              gotoPage(`/lending-pool/${pid}`);
            } else {
              dispatch(getLendingPoolDetailsStart(pid));
            }
          }
          closeSucceededModal();
        }}
        txhash={txhash}
      />
      <ModalFailed visible={visibleFailedModal} onClose={closeFailedModal} />

      <ModalConfirmDelete />
      <ModalConfirmHidePool />
    </div>
  );
};

export default FormLendingPool;
