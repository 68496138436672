import React from 'react';
import ModalComponent from 'components/Modal';
import IconOpenTab from 'resources/images/IconOpenTab';
import IconSucceeded from 'resources/images/IconSucceeded';
import { ConfigNetwork } from 'utils';

const ModalSucceeded = ({ visible, token, onClose, txhash }) => {
  return (
    <ModalComponent visible={visible} onClose={onClose} title="Succeeded" wrapClassName="metamask-modal" width={500}>
      <IconSucceeded />
      <div className="description">
        You have successfully deployed the <b>{token}</b> pool
      </div>
      <a
        href={`${ConfigNetwork.explorer}/${txhash}`}
        target="_blank"
        rel="noopener noreferrer"
        className="button-explore"
      >
        <IconOpenTab />
        <span>View on PolygonScan</span>
      </a>
    </ModalComponent>
  );
};

export default ModalSucceeded;
