import { Button, Dropdown, Space, Tooltip } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { withRouter } from 'react-router-dom';

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'utils/customHooks';
import UserDropdown from './UserDropdown';
import Logo from 'resources/images/logo.png';

import { ethers } from 'ethers';
import ModalNoMetamask from 'components/ModalMetamask/NoMetamask';
import ModalWrongNetwork from 'components/ModalMetamask/WrongNetwork';
import { selectAddress } from 'redux/auth/selected';
import { clearAddress, saveAddress } from 'redux/auth/slice';
import LogoMetamask from 'resources/images/logo-metamask.jpg';
import { formatAddress } from 'components/NumberFormat/utils';
import { ConfigNetwork } from 'utils';

declare let window: any;

const HeaderCommon: FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [chainId, setChainId] = useState<number | undefined>();
  const [currentAccount, setCurrentAccount] = useState<string | undefined>();
  const [visibleNoMetamaskModal, setVisibleNoMetamaskModal] = useState(false);
  const [visibleWrongNetworkModal, setVisibleWrongNetworkModal] = useState(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const address = useAppSelector(selectAddress);

  const getAccountActive = async () => {
    if (!window.ethereum) {
      return;
    }

    await window.ethereum.on('accountsChanged', (accounts) => {
      if (!accounts?.length) {
        onClickDisconnect();
      }
    });
  };

  const getAccountChain = async () => {
    if (!window.ethereum) {
      return;
    }

    await window.ethereum.on('chainChanged', (chain) => {
      const chainId = ethers.BigNumber.from(chain).toString();
      setChainId(+chainId);
    });
  };

  const toggleNoMetamaskModal = () => {
    setVisibleNoMetamaskModal(!visibleNoMetamaskModal);
  };

  const openWrongNetworkModal = () => {
    setVisibleWrongNetworkModal(true);
  };

  const closeWrongNetworkModal = () => {
    setVisibleWrongNetworkModal(false);
  };

  useEffect(() => {
    getAccountActive();
    getAccountChain();
  }, []);

  useEffect(() => {
    if (address && ethers.utils.isAddress(address)) {
      setCurrentAccount(address);
    }
  }, [address]);

  useEffect(() => {
    if (currentAccount) {
      if (chainId && chainId !== ConfigNetwork.chainId) {
        openWrongNetworkModal();
      } else {
        closeWrongNetworkModal();
      }
    }
  }, [currentAccount, chainId]);

  useEffect(() => {
    if (!currentAccount || !ethers.utils.isAddress(currentAccount)) return;
    //client side code
    if (!window.ethereum) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    provider.getNetwork().then((result) => {
      setChainId(result.chainId);
    });
  }, [currentAccount]);

  const onClickConnect = () => {
    if (!window.ethereum) {
      toggleNoMetamaskModal();
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider
      .send('eth_requestAccounts', [])
      .then((accounts) => {
        if (accounts.length > 0) {
          dispatch(saveAddress(accounts[0]));
        }
      })
      .catch((e) => console.log(e));
  };

  const onClickDisconnect = () => {
    setCurrentAccount(undefined);
    dispatch(clearAddress());
  };

  return (
    <Header className="header">
      <div className="header__right">
        {currentAccount ? (
          <Tooltip title="Disconnect">
            <Button
              onClick={onClickDisconnect}
              icon={<img src={LogoMetamask} alt="Logo Metamask" />}
              className="button-connect"
            >
              {formatAddress(currentAccount)}
            </Button>
          </Tooltip>
        ) : (
          <Button onClick={onClickConnect} className="button-connect">
            Connect MetaMask
          </Button>
        )}
        <Dropdown overlay={UserDropdown(t)} trigger={['click']} className="header__profile">
          <Button>
            <Space size={12}>
              <div className="username" title={currentUser?.username}>
                {t('common.admin')}
              </div>
              <img src={Logo} alt={currentUser.username} />
            </Space>
          </Button>
        </Dropdown>
      </div>
      <ModalNoMetamask visible={visibleNoMetamaskModal} onClose={toggleNoMetamaskModal} />
      <ModalWrongNetwork visible={visibleWrongNetworkModal} />
    </Header>
  );
};
export default withRouter(HeaderCommon);
