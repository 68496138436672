import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: {
    token: '',
    avatarUrl: '',
    email: '',
    username: '',
    role: '',
  },
  isForgotPassword: false,
  address: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state, action) => ({
      ...state,
    }),
    loginSuccess: (state, action) => {
      state.currentUser = action.payload;
    },
    changePasswordStart: (state, action) => ({
      ...state,
    }),
    logout: (state) => {
      state.currentUser = initialState.currentUser;
    },
    saveAddress: (state, action) => {
      state.address = action.payload;
    },
    clearAddress: (state) => {
      state.address = initialState.address;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginStart, loginSuccess, changePasswordStart, logout, saveAddress, clearAddress } = authSlice.actions;

export default authSlice.reducer;
