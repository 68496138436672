import { HTTP_RESPONSE } from 'common/constant';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getListBannerAPI,
  createBannerAPI,
  editBannerAPI,
  getBannerDetailsAPI,
  deleteBannerAPI,
} from 'services/banner';
import { ResponseGenerator } from '../../types';
import {
  createBannerStart,
  deleteBannerStart,
  getBannerDetailsStart,
  getBannerDetailsSuccess,
  getListBannerStart,
  getListBannerSuccess,
  editBannerStart,
} from './slice';

function* getListBanner() {
  try {
    const response: ResponseGenerator = yield getListBannerAPI();

    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getListBannerSuccess(response?.data));
    }
  } catch (error: any) {}
}

function* createBanner({ payload }: any) {
  const { data, callback } = payload;

  try {
    const response: any = yield createBannerAPI(data);
    if (response?.status === HTTP_RESPONSE.CREATED) {
      callback(true);
    } else {
      callback(false, response?.data?.message);
    }
  } catch (error: any) {
    callback(false, error?.message);
  }
}

function* getBannerDetails({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getBannerDetailsAPI(payload);

    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getBannerDetailsSuccess(response.data));
    }
  } catch (error: any) {}
}

function* editBanner({ payload }: any) {
  const { id, data, callback } = payload;

  try {
    const response: any = yield editBannerAPI(id, data);
    if (response?.status === HTTP_RESPONSE.OK) {
      callback(true);
    } else {
      callback(false, response?.data?.message);
    }
  } catch (error: any) {
    callback(false, error?.message);
  }
}

function* deleteBanner({ payload }: any) {
  const { id, callback } = payload;

  try {
    const response: ResponseGenerator = yield deleteBannerAPI(id);

    if (response?.status === HTTP_RESPONSE.OK) {
      callback(true);
    } else {
      callback(false, response?.data?.message);
    }
  } catch (error: any) {
    callback(false, error?.message);
  }
}

function* watchBanner() {
  yield takeLatest(createBannerStart, createBanner);
  yield takeLatest(getListBannerStart, getListBanner);
  yield takeLatest(getBannerDetailsStart, getBannerDetails);
  yield takeLatest(editBannerStart, editBanner);
  yield takeLatest(deleteBannerStart, deleteBanner);
}

export default watchBanner;
