import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TableComponent from 'components/Table';
import { columns } from './column';
import IconSearch from 'resources/images/IconSearch';
import PageHeaderComponent from 'components/PageHeader';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import useConfirm from 'hook/useConfirm';
import { useAppDispatch, useAppSelector } from 'utils/customHooks';
import { deleteLendingPoolRequest, getListLendingPoolRequest } from 'redux/leadingPool/slice';
import { selectListLendingPools } from 'redux/leadingPool/selected';
import { SORT_ORDER, PARAMS_TABLE } from 'common/constant';

const { ASCEND, DESCEND } = SORT_ORDER;

const LendingPool = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const listLendingPools = useAppSelector(selectListLendingPools);

  const [queryParams, setQueryParams] = useState({
    keyword: '',
    sortField: 'createdAt',
    sortType: DESCEND,
    ...PARAMS_TABLE,
  });
  const [activeRecord, setActiveRecord] = useState({});

  const [ModalConfirmDelete, onConfirmDelete] = useConfirm({
    title: 'Confirmation',
    message: `Do you really want to delete the ${activeRecord?.lendingToken?.name}/${activeRecord?.interestToken?.name} pool?`,
    buttonCancelProps: {
      text: 'No',
    },
    buttonConfirmProps: {
      text: 'Yes',
      className: 'button-save',
    },
    modalProps: {
      width: 441,
    },
  });

  const handleNameSearch = (data) => {
    setQueryParams({
      ...queryParams,
      keyword: data.target.value,
    });
  };

  const handleSearch = (data) => {
    if (data.key === 'Enter') {
      setQueryParams({
        ...queryParams,
        keyword: queryParams.keyword?.trim(),
        offset: 0,
      });
    }
  };

  const handleChangeBlur = () => {
    setQueryParams({
      ...queryParams,
      keyword: queryParams.keyword?.trim(),
      offset: 0,
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    setQueryParams((prevState) => ({
      ...prevState,
      limit: pageSize,
      offset: (current - 1) * pageSize,
      sortField: field,
      sortType: order === 'ascend' ? ASCEND : order === 'descend' ? DESCEND : undefined,
    }));
  };

  const goToAddNewTokenPage = () => {
    history.push('/lending-pool/create');
  };

  const handleResponseDeleteToken = (status, errorMessage) => {
    if (status) {
      message.success('Delete pool successfully');
    } else {
      message.error(errorMessage || 'Deletion was unsuccessful');
    }

    dispatch(
      getListLendingPoolRequest({
        ...queryParams,
        sortField: queryParams.sortType ? queryParams.sortField : 'createdAt',
        sortType: queryParams.sortType || DESCEND,
      })
    );
  };

  const onDeleteToken = (id) => async () => {
    const activeRecord = listLendingPools?.data?.find((pool) => pool?._id === id);
    await setActiveRecord(activeRecord);
    const answer = await onConfirmDelete();

    if (answer) {
      dispatch(
        deleteLendingPoolRequest({
          id,
          callback: handleResponseDeleteToken,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getListLendingPoolRequest({
        ...queryParams,
        sortField: queryParams.sortType ? queryParams.sortField : 'createdAt',
        sortType: queryParams.sortType || DESCEND,
      })
    );
  }, [dispatch, JSON.stringify(queryParams)]);

  return (
    <div className="user-management lending-pool-management">
      <PageHeaderComponent
        title="Lending Pool Management"
        extra={
          <Button icon={<FontAwesomeIcon icon={faAdd} />} onClick={goToAddNewTokenPage} key="1" className="button-add">
            Add Pool
          </Button>
        }
      />
      <div className="search">
        <Input
          placeholder="Search"
          value={queryParams.keyword}
          onKeyDown={handleSearch}
          onChange={handleNameSearch}
          suffix={<IconSearch />}
          maxLength={256}
          onBlur={handleChangeBlur}
        />
      </div>
      <TableComponent
        showSizeChanger
        dataSource={listLendingPools?.data?.map((pool, index) => ({
          ...pool,
          index: (queryParams.offset / queryParams.limit) * queryParams.limit + index + 1,
        }))}
        rowKey={(e) => e._id}
        columns={columns({ onDeleteToken })}
        current={queryParams.offset / queryParams.limit + 1}
        pageSize={queryParams.limit}
        total={listLendingPools?.total || 0}
        scroll={{ x: 1200 }}
        onChangeTable={handleChangeTable}
        // tableLayout="fixed"
      />
      <ModalConfirmDelete />
    </div>
  );
};

export default LendingPool;
