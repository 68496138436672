import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  banner: {},
  participantDetail: {
    records: [],
    total: 0,
  },
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getListBannerStart: (state, action) => ({
      ...state,
    }),
    getListBannerSuccess: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    createBannerStart: (state, action) => ({
      ...state,
    }),
    getBannerDetailsStart: (state, action) => ({
      ...state,
    }),
    getBannerDetailsSuccess: (state, action) => ({
      ...state,
      banner: {
        ...state.banner,
        [action.payload._id]: action.payload,
      },
    }),
    editBannerStart: (state, action) => ({
      ...state,
    }),
    deleteBannerStart: (state, action) => ({
      ...state,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  getListBannerStart,
  getListBannerSuccess,
  createBannerStart,
  getBannerDetailsStart,
  getBannerDetailsSuccess,
  editBannerStart,
  deleteBannerStart,
} = bannerSlice.actions;

export default bannerSlice.reducer;
