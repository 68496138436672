import { faImage, faTable, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssetPage from 'pages/asset';
import AssetDetailsPage from 'pages/asset/details';
import LendingPool from 'pages/lendingPool';
import FormLendingPool from 'pages/lendingPool/form';
import ChangePasswordPage from 'pages/changePassword';
import React, { lazy } from 'react';
import { Redirect } from 'react-router';
import IconUserManagement from 'resources/images/IconUserManagement';
import { PayCircleFilled } from '@ant-design/icons';

const LoginPage = lazy(() => import('pages/login'));
const User = lazy(() => import('pages/user'));
const UserDetail = lazy(() => import('pages/user/form'));
const BannerPage = lazy(() => import('pages/banner'));
const BannerForm = lazy(() => import('pages/banner/form'));

export const publicRoutes = [
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    exact: true,
  },
];

export const privateRoutes = (t) => {
  return [
    {
      name: t('menu.text_wallet'),
      path: '/',
      component: () => <Redirect to="/token" />,
      exact: true,
      title: 'User',
      icon: <IconUserManagement />,
      inMenu: false,
    },
    {
      name: 'Coin/Token Management',
      path: '/token',
      component: User,
      exact: true,
      title: 'Coin/Token Management',
      icon: <FontAwesomeIcon icon={faTable} />,
      inMenu: true,
    },
    {
      name: 'Coin/Token Management',
      path: '/token/create',
      component: UserDetail,
      exact: true,
      title: 'Add New Token',
      icon: '',
      inMenu: false,
      roles: ['admin', 'user'],
    },
    {
      name: 'Coin/Token Management',
      path: '/token/:id',
      component: UserDetail,
      exact: true,
      title: 'Edit Token',
      icon: '',
      inMenu: false,
      roles: ['admin', 'user'],
    },
    {
      name: 'Banner Management',
      path: '/banner',
      component: BannerPage,
      exact: true,
      title: 'Banner Management',
      icon: <FontAwesomeIcon icon={faImage} />,
      inMenu: true,
      roles: ['admin', 'user'],
    },
    {
      name: 'Banner Management',
      path: '/banner/create',
      component: BannerForm,
      exact: true,
      title: 'Add Banner',
      icon: '',
      inMenu: false,
      roles: ['admin', 'user'],
    },
    {
      name: 'Banner Management',
      path: '/banner/:id',
      component: BannerForm,
      exact: true,
      title: 'Edit Banner',
      icon: '',
      inMenu: false,
      roles: ['admin', 'user'],
    },
    {
      name: 'Assets Management',
      path: '/asset',
      component: AssetPage,
      exact: true,
      title: 'Assets Management',
      icon: <FontAwesomeIcon icon={faWallet} />,
      inMenu: true,
      roles: ['admin', 'user'],
    },
    {
      name: 'Wallet Detail',
      path: '/asset/:id',
      component: AssetDetailsPage,
      exact: true,
      title: 'Wallet Detail',
      icon: '',
      inMenu: false,
      roles: ['admin', 'user'],
    },
    {
      name: 'Lending Pool Management',
      path: '/lending-pool',
      component: LendingPool,
      exact: true,
      title: 'Lending Pool Management',
      icon: <PayCircleFilled />,
      inMenu: true,
      roles: ['admin', 'user'],
    },
    {
      name: 'Add Lending Pool',
      path: '/lending-pool/:id',
      component: FormLendingPool,
      exact: true,
      title: 'Add Lending Pool',
      icon: <PayCircleFilled />,
      inMenu: false,
      roles: ['admin', 'user'],
    },
    {
      name: 'Change Password',
      path: '/change-password',
      component: ChangePasswordPage,
      exact: true,
      title: 'Change Password',
      icon: '',
      inMenu: false,
      roles: ['admin', 'user'],
    },
  ];
};
