import React from 'react';
import { Menu } from 'antd';
import { TFunction } from 'i18next';

import changePassIcon from 'resources/images/Lock.svg';
import logoutIcon from 'resources/images/logout.svg';
import { useAppDispatch } from 'utils/customHooks';
import { logout } from 'redux/auth/slice';
import { useHistory } from 'react-router';

const { Item } = Menu;

const UserDropdown = (t: TFunction) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangePass = () => {
    history.push('/change-password');
  };

  return (
    <div className="user-dropdown">
      <Menu>
        <Item key="1" onClick={handleChangePass} icon={<img src={changePassIcon} />}>
          {t('header.changePassword')}
        </Item>
        <hr style={{ height: 1, background: '#E4E9F2' }} />
        <Item key="2" icon={<img src={logoutIcon} />} onClick={handleLogout}>
          {t('header.logout')}
        </Item>
      </Menu>
    </div>
  );
};

export default UserDropdown;
