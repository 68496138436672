import { listUserAPI } from '../../services/user';
import { HTTP_RESPONSE } from 'common/constant';
import { put, takeLatest } from 'redux-saga/effects';
import { ResponseGenerator } from '../../types';
import {
  getListTokenLendingRequest,
  getListTokenLendingResponse,
  createLendingPoolRequest,
  getLendingPoolDetailsStart,
  getLendingPoolDetailsResponse,
  getListLendingPoolRequest,
  getListLendingPoolResponse,
  editLendingPoolAPRRequest,
  deleteLendingPoolRequest,
} from './slice';
import {
  createLendingPoolAPI,
  deleteLendingPoolAPI,
  editLendingPoolAprAPI,
  getLendingPoolDetailsAPI,
  getListLendingPoolAPI,
  getListLendingTokensAPI,
} from 'services/lendingPool';

function* getListTokenLending({ payload }) {
  try {
    const response: ResponseGenerator = yield getListLendingTokensAPI(payload);
    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getListTokenLendingResponse(response.data));
    }
  } catch (error) {
    yield put(getListTokenLendingResponse(error));
  }
}

function* createLendingPool({ payload }) {
  const { data, callback } = payload;
  try {
    const response: ResponseGenerator = yield createLendingPoolAPI(data);
    if ([HTTP_RESPONSE.OK, HTTP_RESPONSE.CREATED].includes(response?.status || 0)) {
      callback && callback(true);
    } else {
      callback && callback(false, response);
    }
  } catch (error) {
    callback && callback(false, error);
  }
}

function* getLendingPoolDetails({ payload }) {
  try {
    const response: ResponseGenerator = yield getLendingPoolDetailsAPI(payload);
    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getLendingPoolDetailsResponse(response?.data));
    }
  } catch (error) {}
}

function* getListLendingPool({ payload }) {
  try {
    const response: ResponseGenerator = yield getListLendingPoolAPI(payload);
    if (response?.status === HTTP_RESPONSE.OK) {
      yield put(getListLendingPoolResponse(response?.data));
    }
  } catch (error) {}
}

function* editLendingPoolAPR({ payload }) {
  const { data, callback } = payload;
  try {
    const response: ResponseGenerator = yield editLendingPoolAprAPI(data);
    if (response?.status === HTTP_RESPONSE.OK) {
      callback && callback(true);
    } else {
      callback && callback(false, response);
    }
  } catch (error) {
    callback && callback(false, error);
  }
}

function* deleteLendingPool({ payload }) {
  const { id, callback } = payload;
  try {
    const response: ResponseGenerator = yield deleteLendingPoolAPI(id);
    if (response?.status === HTTP_RESPONSE.OK) {
      callback && callback(true);
    } else {
      callback && callback(false, response);
    }
  } catch (error) {
    callback && callback(false, error);
  }
}

function* watchLending() {
  yield takeLatest(getListTokenLendingRequest, getListTokenLending);
  yield takeLatest(createLendingPoolRequest, createLendingPool);
  yield takeLatest(getLendingPoolDetailsStart, getLendingPoolDetails);
  yield takeLatest(getListLendingPoolRequest, getListLendingPool);
  yield takeLatest(editLendingPoolAPRRequest, editLendingPoolAPR);
  yield takeLatest(deleteLendingPoolRequest, deleteLendingPool);
}

export default watchLending;
