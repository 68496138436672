/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Input, Select, Switch, DatePicker, Radio, Space, Tag, Upload, message } from 'antd';
import cx from 'classnames';
import { ErrorMessage, Field, FieldConfig, FieldInputProps, FormikProps, useFormikContext } from 'formik';
import { get, toPath, trim } from 'lodash';
import { Moment } from 'moment';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconArrowDown from 'resources/images/IconArrowDown';
import IconCalendar from 'resources/images/IconCalendar';
import { MAX_LENGTH_INPUT, TYPE_INPUT } from '../../common/constant';
import i18n from '../../i18n/i18n';
import NoData from '../../resources/images/No_data.svg';
import { getBase64, passwordStrength } from '../../utils';
import NumberFormat from '../NumberFormat';
import { EyeInvisibleOutlined, EyeOutlined, InboxOutlined } from '@ant-design/icons';

const { Password, Search, TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const levelPassword = [
  { level: 0, text: 'common.low' },
  { level: 1, text: 'common.low' },
  { level: 2, text: 'common.medium' },
  { level: 3, text: 'common.high' },
  { level: 4, text: 'common.high' },
];

export const TextInput: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  value?: any;
  maxLength?: number;
}> = ({ field, form, value, maxLength, ...props }) => {
  const { onChange, onBlur, onKeyDown } = props as any;

  const maxLengthInput = maxLength || MAX_LENGTH_INPUT;

  const handleChange = (e: any) => {
    const { value } = e.target;
    form.setFieldValue(field.name, value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    const { value } = e.target;
    if (!onBlur) {
      form.handleBlur(e);
      form.setFieldValue(field.name, trim(value));
    } else {
      onBlur(e);
    }
  };

  const handleKeyDow = (e: any) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <Input
      maxLength={maxLengthInput}
      {...field}
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value || field.value}
      onKeyDown={handleKeyDow}
    />
  );
};

export const NumberInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  form: FormikProps<any>;
  unit: string;
  thousandSeparator?: boolean;
  onChange?: any;
  decimalScale?: number;
  onValueChange?: any;
  maxVal: number;
}> = ({ field, form, unit, thousandSeparator, onChange, onValueChange, decimalScale, maxVal, ...props }) => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    if (thousandSeparator) {
      return;
    } else {
      field.onChange(e);
    }
  };

  const handleValueChange = (values: any) => {
    if (thousandSeparator) {
      form.setFieldValue(field.name, values?.value);
    }
  };

  // const withValueLimit = ({ floatValue }: any) => {
  //   if (floatValue)
  //     return Number(floatValue) <= maxVal;
  //   else return '0'
  // }

  return (
    <div className="number-input">
      <NumberFormat
        allowNegative={false}
        customInput={Input}
        thousandSeparator={thousandSeparator}
        onValueChange={onValueChange ?? handleValueChange}
        // isAllowed={withValueLimit}
        {...field}
        {...props}
        onChange={onChange ?? handleChange}
        decimalScale={decimalScale ?? 8}
      />
      {unit && <span className="unit">{unit}</span>}
    </div>
  );
};

export const InputTextArea: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  maxLength?: number;
  props: any;
  onBlur?: any;
}> = ({ maxLength, field, form, onBlur, ...props }) => {
  const maxLengthTextarea = maxLength || MAX_LENGTH_INPUT;

  const handleBlur = (event: any) => {
    const { value } = event.target;

    form.handleBlur(event);
    form.setFieldValue(field.name, trim(value));

    if (onBlur) {
      return onBlur(event);
    }
  };

  return <TextArea maxLength={maxLengthTextarea} {...field} {...props} onBlur={handleBlur} />;
};

export const SearchInput: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  maxLength?: number;
}> = ({ field, form, maxLength, ...props }) => {
  const { onChange, onBlur, onSearch } = props as any;

  const maxLengthInput = maxLength || MAX_LENGTH_INPUT;

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (!onChange) {
      form.setFieldValue(field.name, value);
    } else {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    const { value } = e.target;
    form.handleBlur(e);
    form.setFieldValue(field.name, trim(value));

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleSearch = (value: any) => {
    form.setFieldValue(field.name, trim(value));

    if (onSearch) {
      onSearch(value);
    }
  };

  return (
    <Search
      enterButton={i18n?.t('search_panel.button_search')}
      {...field}
      {...props}
      onSearch={handleSearch}
      onChange={handleChange}
      onBlur={handleBlur}
      value={field.value}
      maxLength={maxLengthInput}
    />
  );
};

export const PasswordInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  showLevelPassword?: boolean;
  label?: any;
  labelClassName?: string;
  form: FormikProps<any>;
}> = ({ field, showLevelPassword, label, labelClassName, form, ...props }) => {
  const fieldVal = field.value;
  const addClassLevel =
    passwordStrength(fieldVal) < 2
      ? 'input__label--low'
      : passwordStrength(fieldVal) < 3
      ? 'input__label--medium'
      : passwordStrength(fieldVal) < 5
      ? 'input__label--high'
      : '';
  const { t } = useTranslation('common');
  return (
    <>
      {label && (
        <div className={cx('form-item__label', labelClassName)}>
          {label}
          {!!fieldVal && !!showLevelPassword && (
            <div className="input__label--level">
              {levelPassword.map((item) => (
                <span
                  className={`input__label--level-item ${item.level <= passwordStrength(fieldVal) && addClassLevel}`}
                  key={item.level}
                />
              ))}
              <span className={`input__label--level-title ${addClassLevel}`}>
                {t(levelPassword.filter((item) => item.level === passwordStrength(fieldVal))[0]?.text)}
              </span>
            </div>
          )}
        </div>
      )}
      <Password
        iconRender={(visible) => (!visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
        {...field}
        {...props}
      />
    </>
  );
};

export const SelectInput: FC<{
  field: FieldInputProps<any>;
  props: FieldConfig;
  form: FormikProps<any>;
  options: any;
  prefix?: any;
  className?: string;
  onChange?: any;
  mode?: any;
  values?: any;
  optionsType?: any;
  enableAllOption?: any;
  dropdownRender?: any;
  onPopupScroll?: any;
}> = ({
  field,
  form,
  options,
  prefix,
  className,
  onChange,
  optionsType,
  enableAllOption,
  dropdownRender,
  ...props
}) => {
  const ALL_OPTIONS = 'all-options';
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const { value } = field;

  useEffect(() => {
    setIndeterminate(!!value && !!value.length && value.length < options.length);
    setCheckAll(!!value && !!value.length && value.length === options.length);
  }, [value, options.length]);

  const tagRender = (props: any) => {
    const { label, value } = props;

    const handleRemoveTag = () => {
      const newValue = form.values[field.name]?.filter((item) => item !== value);

      form.setFieldValue(field.name, newValue);
    };

    return (
      <div className="search-form__select-item--multiple">
        <Tag closable onClose={handleRemoveTag}>
          {label} &nbsp;
        </Tag>
      </div>
    );
  };

  const onCheckAllOptions = (event: any) => {
    const { checked } = event.target;
    let values = [];
    if (checked) {
      values = options.map((option: any) => option.value);
    } else {
      values = [];
    }
    setIndeterminate(false);
    setCheckAll(checked);
    onChangeSelect(values);
  };

  const optionsSelectAllRender = () => {
    switch (optionsType) {
      case TYPE_INPUT.CHECKBOX: {
        return (
          <div className="search-form__all-options">
            <Checkbox onChange={onCheckAllOptions} id={ALL_OPTIONS} indeterminate={indeterminate} checked={checkAll}>
              {i18n?.t('common.all')}
            </Checkbox>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const optionsRender = (item: any) => {
    switch (optionsType) {
      case TYPE_INPUT.CHECKBOX: {
        return (
          <Checkbox id={item.value} checked={value && value.indexOf(item.value) >= 0}>
            <div onClick={onPreventMouseDown}>{item.name}</div>
          </Checkbox>
        );
      }
      default: {
        return (
          <span>
            {item?.imgUrl && <img style={{ marginRight: '5px' }} width={30} src={item?.imgUrl} />}
            {item?.name}
          </span>
        );
      }
    }
  };

  const onPreventMouseDown = (event: any) => {
    event.stopPropagation();
  };

  const onChangeSelect = (val: any) => {
    form.setFieldValue(field.name, val);
    if (onChange) {
      onChange({ form, field, val });
    }
  };

  const renderDropdown = (menu: any) => {
    return (
      <Fragment>
        {enableAllOption && options.length > 1 && optionsSelectAllRender()}
        {menu}
      </Fragment>
    );
  };

  return (
    <div className={className}>
      {prefix}
      <Select
        {...field}
        {...props}
        onChange={onChangeSelect}
        // tagRender={tagRender}
        notFoundContent={
          <div className="ant-empty-text">
            <div className="img-no-data">
              <img src={NoData} alt="No Data" />
            </div>
            <p>{i18n?.t('common.noData')}</p>
          </div>
        }
        suffixIcon={<IconArrowDown />}
        dropdownRender={dropdownRender || renderDropdown}
      >
        {options.map((item: any, index: any) => (
          <Option
            value={item.value}
            key={item?.key || item.value}
            label={
              <>
                {item?.imgUrl && <img style={{ marginRight: '5px' }} width={30} src={item?.imgUrl} />}
                {item?.name}
              </>
            }
            disabled={!!item?.disabled}
          >
            {optionsRender(item)}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const CheckboxInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  form: FormikProps<any>;
  content: any;
}> = ({ field, content, form, ...props }) => (
  <Checkbox {...field} {...props} checked={field.value}>
    {content}
  </Checkbox>
);

export const SwitchInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  form: FormikProps<any>;
  suffix: any;
}> = ({ field, form, suffix, ...props }) => {
  const onChange = (checked: boolean) => {
    form.setFieldValue(field.name, checked);
  };
  return (
    <>
      <Switch checked={!!field.value} {...field} {...props} onChange={onChange} /> {suffix}
    </>
  );
};

export const DatePickerInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  form: FormikProps<any>;
  readOnly?: boolean;
  value?: any;
}> = ({ field, form, readOnly = false, value, ...props }) => {
  const { onChange, disabledDate } = props as any;

  const handleDisabledDate = (current: Moment) => {
    if (disabledDate) {
      return disabledDate(current, form);
    }

    return false;
  };

  const handleChange = (value: any) => {
    form.setFieldValue(field.name, value);

    if (onChange) {
      onChange({ form, field, value });
    }
  };

  return (
    <DatePicker
      {...field}
      {...props}
      onChange={handleChange}
      value={value || field.value}
      disabledDate={handleDisabledDate}
      inputReadOnly={readOnly}
      suffixIcon={<IconCalendar />}
    />
  );
};

export const RadioInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  form: FormikProps<any>;
  options: {
    value: any;
    name: any;
    disabled?: boolean;
  }[];
  defaultValue: any;
}> = ({ field, form, options, defaultValue, ...props }) => {
  return (
    <Radio.Group {...field} {...props} defaultValue={defaultValue}>
      <Space direction="vertical">
        {(options || []).map((item: any) => (
          <Radio key={item.value} value={item.value} disabled={item.disabled}>
            {item.name}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );

  //  <Switch {...field} {...props} checked={!!field.value} onChange={onChange} />;
};

export const DraggerInput: FC<{
  field: FieldInputProps<any>;
  props: any;
  form: FormikProps<any>;
  options: {
    value: any;
    name: any;
    disabled?: boolean;
  }[];
  defaultValue: any;
}> = ({ field, form, options, defaultValue, ...props }) => {
  const [previewImage, setPreviewImage] = useState('');

  const handleCustomRequest = async (props) => {
    setTimeout(() => {
      props.onSuccess('ok');
    }, 0);
  };

  const beforeUpload = (file) => {
    const isValidFileType = ['image/jpeg', 'image/png'].includes(file.type);
    const isValidFileSize = file.size / 1024 / 1024 < 5;

    if (!isValidFileType) {
      message.error('File format is invalid.');
    } else if (!isValidFileSize) {
      message.error('File size cannot exceed 5MB.');
    }

    return (isValidFileType && isValidFileSize) || Upload.LIST_IGNORE;
  };

  const handleUpload = (event: any) => {
    const { originFileObj } = event.file;
    form.setFieldValue(field.name, originFileObj);
    getBase64(originFileObj, (value: any) => {
      setPreviewImage(value);
    });
  };

  return (
    <Dragger
      multiple={false}
      customRequest={handleCustomRequest}
      beforeUpload={beforeUpload}
      onChange={handleUpload}
      onDrop={(file) => {
        console.log('drop', file);
      }}
      maxCount={1}
      showUploadList={false}
      {...props}
    >
      {previewImage || form.values[field.name] ? (
        <>
          <img src={previewImage || form.values[field.name]} alt="Feature image" className="dragger-image" />
        </>
      ) : (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Drag & drop file or <br />
            Upload from your device
          </p>
          <p className="ant-upload-hint">File types supported: JPG, JPEG, PNG. Max size: 5MB.</p>
        </>
      )}
    </Dragger>
  );
};

type FormItemType = {
  component?: any;
  type?: string;
  name: string;
  typeInput?: string | null;
  prefix?: any;
  placeholder?: any;
  options?: {
    value: any;
    name: any;
    disabled?: any;
  }[];
  dropdownClassName?: string;
  className?: string;
  content?: any;
  label?: any;
  showLevelPassword?: boolean;
  maxLength?: number;
  onChange?: any;
  showSearch?: boolean;
  filterOption?: any;
  dropdownMatchSelectWidth?: any;
  labelClassName?: string;
  containerClassName?: string;
  errorClassName?: string;
  decimalScale?: number;
  autoFocus?: boolean;
  required?: boolean;
  children?: any;
  inputProps?: any;
  mode?: any;
  showArrow?: any;
  maxTagCount?: any;
  maxTagTextLength?: any;
  onSearch?: any;
  tagRender?: any;
  optionLabelProp?: any;
  values?: any;
  optionsType?: any;
  enableAllOption?: any;
  errorField?: string;
  description?: any;
  fetchData?: any;
  renderOption?: any;
  limit?: string | number;
  getPopupContainer?: any;
  value?: any;
  disabled?: boolean;
  unit?: string;
  thousandSeparator?: boolean;
  onValueChange?: any;
  onBlur?: any;
  isAllowed?: any;
  enterButton?: any;
  virtual?: boolean;
  rows?: number;
  disabledDate?: any;
  fixedDecimalScale?: boolean;
  isNumericString?: boolean;
  suffix?: any;
  checkedChildren?: any;
  unCheckedChildren?: any;
  showTime?: any;
  disabledTime?: any;
  showCount?: boolean;
  checked?: boolean;
  onKeyDown?: any;
  showNow?: boolean;
  onPopupScroll?: any;
  defaultOpen?: any;
  open?: any;
};

const FormItem = ({
  component,
  placeholder,
  type,
  name,
  typeInput = TYPE_INPUT.TEXT,
  prefix,
  options,
  dropdownClassName,
  className,
  content,
  label,
  labelClassName,
  containerClassName,
  errorClassName,
  required,
  children,
  errorField,
  description,
  disabled,
  unit,
  ...props
}: FormItemType) => {
  let componentRender: any = component || TextInput;
  const { errors, touched } = useFormikContext();

  switch (typeInput) {
    case TYPE_INPUT.TEXT:
      componentRender = TextInput;
      break;
    case TYPE_INPUT.TEXTAREA:
      componentRender = InputTextArea;
      break;
    case TYPE_INPUT.PASSWORD:
      componentRender = PasswordInput;
      break;
    case TYPE_INPUT.SELECT:
      componentRender = SelectInput;
      break;
    case TYPE_INPUT.CHECKBOX:
      componentRender = CheckboxInput;
      break;
    case TYPE_INPUT.NUMBER:
      componentRender = NumberInput;
      break;
    case TYPE_INPUT.SEARCH:
      componentRender = SearchInput;
      break;
    case TYPE_INPUT.SWITCH:
      componentRender = SwitchInput;
      break;
    case TYPE_INPUT.DATE:
      componentRender = DatePickerInput;
      break;
    case TYPE_INPUT.RADIO:
      componentRender = RadioInput;
      break;
    case TYPE_INPUT.DRAGGER:
      componentRender = DraggerInput;
      break;
  }
  const propsField: any = {
    prefix,
    placeholder,
    options,
    className,
    content,
    disabled,
    ...props,
  };
  if (typeInput === TYPE_INPUT.SELECT || typeInput === TYPE_INPUT.SELECT_INFINITY_SCROLL) {
    propsField.dropdownClassName = dropdownClassName;
  }
  if (typeInput === TYPE_INPUT.PASSWORD) {
    propsField.labelClassName = labelClassName;
  }

  function getIn(obj: any, key: string | string[], def?: any, p: number = 0) {
    const path = toPath(key);

    while (obj && p < path.length) {
      obj = obj[path[p++]];
    }
    return obj === undefined ? def : obj;
  }

  return (
    <div className={cx(containerClassName, 'form-item')}>
      {label && typeInput !== TYPE_INPUT.PASSWORD && (
        <div className={cx(labelClassName, 'form-item__label')}>
          {label} {required ? <span style={{ color: 'red' }}>*</span> : ''}
        </div>
      )}
      <Field
        type={type}
        name={name}
        placeholder={placeholder}
        label={label}
        component={componentRender}
        unit={unit}
        {...propsField}
      />
      {description && <div className={cx(labelClassName, 'form-item__description')}>{description}</div>}
      {get(errors, name) && get(touched, name) && (
        <ErrorMessage name={errorField || name} component="div" className={cx('error-text', errorClassName)} />
      )}
      {children}
    </div>
  );
};

export default memo(FormItem);
