import * as React from 'react';

function IconFailed(props: any) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_13_26869)">
        <path
          d="M32 60C24.5739 60 17.452 57.05 12.201 51.799C6.94999 46.548 4 39.4261 4 32C4 24.5739 6.94999 17.452 12.201 12.201C17.452 6.94999 24.5739 4 32 4C39.4261 4 46.548 6.94999 51.799 12.201C57.05 17.452 60 24.5739 60 32C60 39.4261 57.05 46.548 51.799 51.799C46.548 57.05 39.4261 60 32 60ZM32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64Z"
          fill="#F35B37"
        />
        <path
          d="M18.5837 18.5875C18.7695 18.4012 18.9902 18.2534 19.2331 18.1526C19.4761 18.0518 19.7366 17.9999 19.9997 17.9999C20.2627 17.9999 20.5232 18.0518 20.7662 18.1526C21.0092 18.2534 21.2299 18.4012 21.4157 18.5875L31.9997 29.1755L42.5837 18.5875C42.7696 18.4015 42.9904 18.254 43.2333 18.1534C43.4763 18.0527 43.7367 18.0009 43.9997 18.0009C44.2627 18.0009 44.5231 18.0527 44.766 18.1534C45.009 18.254 45.2297 18.4015 45.4157 18.5875C45.6016 18.7734 45.7491 18.9942 45.8498 19.2371C45.9504 19.4801 46.0022 19.7405 46.0022 20.0035C46.0022 20.2664 45.9504 20.5268 45.8498 20.7698C45.7491 21.0128 45.6016 21.2335 45.4157 21.4195L34.8277 32.0035L45.4157 42.5875C45.6016 42.7734 45.7491 42.9942 45.8498 43.2371C45.9504 43.4801 46.0022 43.7405 46.0022 44.0035C46.0022 44.2664 45.9504 44.5268 45.8498 44.7698C45.7491 45.0128 45.6016 45.2335 45.4157 45.4195C45.2297 45.6054 45.009 45.7529 44.766 45.8536C44.5231 45.9542 44.2627 46.006 43.9997 46.006C43.7367 46.006 43.4763 45.9542 43.2333 45.8536C42.9904 45.7529 42.7696 45.6054 42.5837 45.4195L31.9997 34.8315L21.4157 45.4195C21.2297 45.6054 21.009 45.7529 20.766 45.8536C20.5231 45.9542 20.2627 46.006 19.9997 46.006C19.7367 46.006 19.4763 45.9542 19.2333 45.8536C18.9904 45.7529 18.7696 45.6054 18.5837 45.4195C18.3977 45.2335 18.2502 45.0128 18.1496 44.7698C18.0489 44.5268 17.9972 44.2664 17.9972 44.0035C17.9972 43.7405 18.0489 43.4801 18.1496 43.2371C18.2502 42.9942 18.3977 42.7734 18.5837 42.5875L29.1717 32.0035L18.5837 21.4195C18.3974 21.2337 18.2497 21.013 18.1488 20.77C18.048 20.527 17.9961 20.2665 17.9961 20.0035C17.9961 19.7404 18.048 19.4799 18.1488 19.2369C18.2497 18.9939 18.3974 18.7732 18.5837 18.5875Z"
          fill="#F35B37"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_26869">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFailed;
