import * as React from "react";

function IconSearch(props: any) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16663 1.66602C13.3088 1.66602 16.6666 5.02388 16.6666 9.16602C16.6666 10.9367 16.053 12.564 15.0269 13.847L18.0892 16.9101C18.4147 17.2355 18.4147 17.7632 18.0892 18.0886C17.7888 18.389 17.3161 18.4121 16.9892 18.1579L16.9107 18.0886L13.8476 15.0263C12.5646 16.0524 10.9373 16.666 9.16663 16.666C5.02449 16.666 1.66663 13.3082 1.66663 9.16602C1.66663 5.02388 5.02449 1.66602 9.16663 1.66602ZM9.16663 3.33268C5.94496 3.33268 3.33329 5.94435 3.33329 9.16602C3.33329 12.3877 5.94496 14.9993 9.16663 14.9993C12.3883 14.9993 15 12.3877 15 9.16602C15 5.94435 12.3883 3.33268 9.16663 3.33268Z"
        fill="#8F9BB3"
      />
    </svg>
  );
}

export default IconSearch;
