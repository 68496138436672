import React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, message } from 'antd';

import { logout } from 'redux/auth/slice';
import { changePasswordAPI } from 'services/auth';
import { useAppDispatch } from 'utils/customHooks';
import { HTTP_RESPONSE, TYPE_INPUT } from 'common/constant';
import FormItem from 'components/FormItem';
import PageHeaderComponent from 'components/PageHeader';

const ChangePasswordPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('This field is required')
      .min(6, 'The new password field must be at least 6 characters'),
    confirmPassword: Yup.string()
      .required('This field is required')
      .when('password', {
        is: (value) => value,
        then: Yup.string().oneOf([Yup.ref('password')], 'The confirm new password fields do not match'),
      }),
  });

  const handleSubmit = async (values, formikHelper) => {
    const { password } = values;

    const response = await changePasswordAPI({ password });

    if (response?.status === HTTP_RESPONSE.OK) {
      message.success('Change password successfully');
      formikHelper.resetForm();
      dispatch(logout());
    } else {
      message.error(response?.message || 'Change password failed');
    }
  };

  const handleBack = () => {
    history.push('/');
  };

  return (
    <>
      <PageHeaderComponent title="Change password" onBack={handleBack} />
      <div className="user-management change-password">
        <div className="change-password__content">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <FormItem typeInput={TYPE_INPUT.PASSWORD} name="password" placeholder="New Password" />
              <FormItem typeInput={TYPE_INPUT.PASSWORD} name="confirmPassword" placeholder="Confirm New Password" />
              <Button htmlType="submit" className="button-submit">
                Change
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPage;
